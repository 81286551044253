import { Role } from "src/shared/stores/role.store";
import { config } from "../../../config";
import { toast } from "react-toastify";

/**
 * Copies the profile URL to the user's clipboard.
 * @param handle - The handle of the profile to copy the URL for.
 * @param role - The role of the user. Defaults to 'startup'.
 * If no handle is provided, shows an error message.
 */
export const handleCopyProfileUrl = (handle?: string, role: Role = 'startup') => {
  if (handle) {
    navigator.clipboard
      .writeText(`${config?.AppBaseURL}/${role}/${handle}`)
      .then(() => {
        toast.success('Profile URL copied!');
      })
      .catch(error => {
        console.error('Error copying URL: ', error);
      });
  }
  else {
    toast.error('Set the profile URL first in the edit profile section.');
  }
};
