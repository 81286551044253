const PATHS = {
  HOME: '/home',
  CHAT: '/chat',
  STARTUPS: '/startups',
  INVESTORS: '/investors',
  FOLLOWERS: '/followers',
  USER_SETTINGS: '/user-settings',
  POST: '/post/:id',
  CHANGE_PASSWORD: '/change-password',
};

export default PATHS;
