import { config } from '../config';
import { getToken } from '../modules/auth/utils/token';
import { request } from './requests';
import { AxiosRequestConfig } from 'axios';

const API_BASE_URL = config.ApiBaseURL;

interface RequestOptions<T> {
  route: string;
  data?: T;
  isPublic?: boolean;
}

function makeRequest<T, U>(method: AxiosRequestConfig['method'], options: RequestOptions<T>) {
  const { route, data, isPublic } = options;

  const url = `${API_BASE_URL}${route}`;

  // Defult configuration for the request
  const config: AxiosRequestConfig = {
    method,
    url,
    data,
    headers: {},
  };

  const token = getToken();
  if (!isPublic && token) {
    config.headers = { ...config.headers, Authorization: `${token}` };
  }

  return request<U>(config);
}

function get<T, U>(options: RequestOptions<T>) {
  return makeRequest<T, U>('GET', options);
}

function post<T, U>(options: RequestOptions<T>) {
  return makeRequest<T, U>('POST', options);
}

function put<T, U>(options: RequestOptions<T>) {
  return makeRequest<T, U>('PUT', options);
}

function del<T, U>(options: RequestOptions<T>) {
  return makeRequest<T, U>('DELETE', options);
}

const APIClient = {
  get,
  post,
  put,
  del,
};

export default APIClient;
