import InvestorService from '../../../api/services/profiles/investor.service';
import { useState } from 'react';
import { toast } from 'react-toastify';
import useUserStore from 'src/shared/stores/user.store';
import { blobToBase64 } from 'src/shared/utils/files';

interface Props {
  onCompleted: () => void;
}

export const useUpdateInvestorProfile = ({ onCompleted }: Props) => {
  const { setUser } = useUserStore();
  const [loading, setLoading] = useState(false);

  const handleUpdateInvestorProfile = async (data: any) => {
    try {
      setLoading(true);

      const response = await InvestorService.updateInvestorProfile(data);

      if (response?.success) {
        if (data instanceof FormData) {
          const profilePhotoBlob = data.get('profilePhoto');

          if (profilePhotoBlob) {
            const profilePhoto = await blobToBase64(profilePhotoBlob as Blob);
            setUser({
              ...(profilePhoto && { profilePhoto }),
            });
          }
        }
        else {
          const { firstName, lastName } = data;
          setUser({
            ...(firstName && { firstName }),
            ...(lastName && { lastName }),
          });
        }

        onCompleted();
        toast.success('Investor profile updated successfully');
      }
      else {
        toast.error(response?.message);
      }
    }
    catch (error) {
      toast.error((error as Error)?.message);
    }
    finally {
      setLoading(false);
    }
  };

  return {
    handleUpdateInvestorProfile,
    loading,
  };
};
