import { useState } from 'react';
import { toast } from 'react-toastify';
import { CreateInvestmentDto } from 'src/api/services/investment/investment.dto';
import InvestmentService from 'src/api/services/investment/investment.service';

interface Props {
  onCompleted: () => void;
}

export const useCreateInvestment = ({ onCompleted }: Props) => {
  const [loading, setLoading] = useState(false);

  const handleCreateInvestment = async (data: CreateInvestmentDto) => {
    try {
      setLoading(true);
      const response = await InvestmentService.createInvestment(data);

      if (response?.success) {
        onCompleted();
        toast.success('Investment created successfully');
      }
      else {
        toast.error(response?.message);
      }
    }
    catch (error) {
      toast.error((error as Error)?.message);
    }
    finally {
      setLoading(false);
    }
  };

  return {
    handleCreateInvestment,
    loading,
  };
};
