export const getTimeAgo = (dateString: string): string => {
  const date = new Date(dateString);
  const now = new Date();

  const timeDifference = now.getTime() - date.getTime();
  const minutesDifference = Math.floor(timeDifference / (1000 * 60));

  if (minutesDifference < 1) return 'Just now';
  if (minutesDifference < 60) return `${minutesDifference} minute${minutesDifference > 1 ? 's' : ''} ago`;

  const hoursDifference = Math.floor(minutesDifference / 60);
  if (hoursDifference < 24) return `${hoursDifference} hour${hoursDifference > 1 ? 's' : ''} ago`;

  const daysDifference = Math.floor(hoursDifference / 24);
  if (daysDifference <= 7) return `${daysDifference} day${daysDifference > 1 ? 's' : ''} ago`;

  return date.toLocaleDateString();
};
