import { useState } from 'react';
import { toast } from 'react-toastify';
import AuthService, { OldAuthService } from '../../../api/services/auth/auth.service';
import { ResetPasswordDto } from '../../../api/services/auth/auth.dto';
import { useConfigStore } from 'src/shared/stores/config.store';

export const useResetPasswordForm = () => {
  const authService = new AuthService();
  const { featureFlags } = useConfigStore();

  const [loading, setLoading] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);

  const handleSubmit = async ({ email }: ResetPasswordDto) => {
    try {
      setLoading(true);

      let response;
      if (featureFlags.supabaseAuth) {
        response = await authService.resetPassword(email);
      }
      else {
        if (isEmailSent) {
          toast.error('Email has already been sent');
          return;
        }

        const resetStatusResponse = await OldAuthService.getResetPasswordStatus(email);
        if (resetStatusResponse.success) {
          setIsEmailSent(resetStatusResponse?.emailStatus);
          if (!resetStatusResponse?.emailStatus) {
            response = await authService.resetPassword(email);
          }
          else {
            toast.error('Email already sent');
            return;
          }
        }
        else {
          toast.error(resetStatusResponse?.message);
        }
      }

      if (response?.success) {
        toast.success(response.message);
      }
      else {
        toast.error(response?.message);
      }
    }
    catch (error) {
      toast.error((error as Error).message);
    }
    finally {
      setLoading(false);
    }
  };

  return {
    handleSubmit,
    loading,
    isEmailSent,
  };
};
