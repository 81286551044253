import { useState } from 'react';
import { toast } from 'react-toastify';
import { b64toBlob, blobToBase64 } from '../../../shared/utils/files';
import { arrayToFormDataValue } from '../utils/arrayToFormDataValue';
import useUserStore from 'src/shared/stores/user.store';
import useRoleStore from 'src/shared/stores/role.store';
import StartupService from 'src/api/services/profiles/startup.service';
import ProfileService from 'src/api/services/profiles/profile.service';

interface Props {
  onCompleted: () => void;
}

export const useStartupSetupForm = ({ onCompleted }: Props) => {
  const { setUser } = useUserStore();
  const { roles, addRole, setCurrentRole } = useRoleStore();
  const [loading, setLoading] = useState(false);

  const hasCreatedStartupProfile = roles.includes('startup');

  const handleSubmit = async (data: any) => {
    try {
      setLoading(true);

      const formData = new FormData();

      Object.entries(data).forEach(([key, value]) => {
        if (key === 'interestCategories') {
          formData.append(key, arrayToFormDataValue(value));
        }
        else if (value) {
          const formValue = key === 'profilePhoto' ? b64toBlob(String(value)) : String(value);
          formData.append(key, formValue);
        }
      });

      const response = hasCreatedStartupProfile
        ? await ProfileService.updateStartupProfile(formData)
        : await StartupService.createStartup(formData);

      if (response?.success) {
        const profilePhotoBlob = formData.get('profilePhoto');
        const { companyName } = data;

        setUser({
          ...(companyName && { companyName }),
        });

        if (profilePhotoBlob) {
          const companyProfilePhoto = await blobToBase64(profilePhotoBlob as Blob);
          setUser({
            ...(companyProfilePhoto && { companyProfilePhoto }),
          });
        }

        onCompleted();
        toast.success('Startup profile updated successfully');
        addRole('startup');
        setCurrentRole('startup');
      }
      else {
        toast.error(response?.message);
      }
    }
    catch (error) {
      toast.error((error as Error)?.message);
    }
    finally {
      setLoading(false);
    }
  };

  return {
    handleSubmit,
    loading,
  };
};
