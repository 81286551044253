const PROFILE_PATHS = {
  // TODO Setup should relate to account setup, not profile setup
  SETUP: '/profile/setup',

  // TODO Should be simply /:handle, for both investor and startup, i.e account
  // TODO Should be route for both public and personal view
  STARTUP_PUBLIC: '/startup/:handle',
  // TODO Deprecated, replaced with STARTUP_PUBLIC
  STARTUP_YOUR: '/startup-profile-view',
  INVESTOR_PUBLIC: '/investor/:handle',
  INVESTOR_YOUR: '/investor-profile-view',
  // TODO Deprecated, replaced with INVESTOR_YOUR
  INVESTOR_EDIT: '/investor-profile-edit',

  SYNDICATES: '/syndicates',
  SYNDICATE_PUBLIC: '/syndicate/:handle',
  SYNDICATE_YOUR: '/syndicate-profile-view',
};

export default PROFILE_PATHS;
