import { Button, Col, Divider, Form, Input, Modal, Row } from 'antd';
import { useCreatePost } from '../hooks/useCreatePost';
import { CreatePostDto } from '../../../api/services/post/post.dto';
import UploadImage from '../../../shared/components/UploadImage';

interface Props {
  open: boolean;
  onCompleted: () => void;
  onClose: () => void;
}

const CreatePostModal = ({ open, onCompleted, onClose }: Props) => {
  const [form] = Form.useForm<CreatePostDto>();

  const title = Form.useWatch('title', form);
  const description = Form.useWatch('description', form);

  const { handleCreatePost, loading } = useCreatePost({
    onCompleted: () => {
      form.resetFields();
      onClose();
      onCompleted();
    },
  });

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      destroyOnClose
      footer={null}
      width={590}
      title={
        <>
          Create a post
          <Divider />
        </>
      }
    >
      <Form form={form} layout="vertical" size="small" onFinish={handleCreatePost}>
        <Row>
          <Col span={24}>
            <Form.Item<CreatePostDto>
              label="Title"
              name="title"
              rules={[{ required: true, whitespace: true, message: 'Please enter title' }]}
              validateTrigger="onSubmit"
            >
              <Input placeholder="Enter an eye-catching title" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item<CreatePostDto>
              label="Description"
              name="description"
              rules={[{ required: true, whitespace: true, message: 'Please enter description' }]}
              validateTrigger="onSubmit"
            >
              <Input.TextArea
                placeholder="Detail your insights for founders and investors"
                autoSize={{ minRows: 6, maxRows: 10 }}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item name="file" validateTrigger="onSubmit">
              <UploadImage />
            </Form.Item>
          </Col>
        </Row>

        <Button htmlType="submit" className="tw-w-full" type="primary" size="middle" loading={loading} disabled={!title || !description}>
          Post
        </Button>
      </Form>
    </Modal>
  );
};

export default CreatePostModal;
