import { create } from 'zustand';
import { persist,createJSONStorage } from 'zustand/middleware';

interface UserState {
  userId?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  profilePhoto?: string | null;
  companyName?: string | null;
  companyProfilePhoto?: string | null;
  syndicateName?: string | null;
  syndicateProfilePhoto?: string | null;
}

interface UserAction {
  setUser: (user: UserState) => void;
  clearUser: () => void;
}

const useUserStore = create(
  persist<UserState & UserAction>(
    (set) => ({
    setUser: (user: UserState) => set((state) => {
      return { ...state, ...user }
    }),
    clearUser: () => set({
      firstName: '',
      lastName: '',
      email: '',
      profilePhoto: null,
      companyName: null,
      companyProfilePhoto: null,
      syndicateName: null,
      syndicateProfilePhoto: null,
    }),
  }),
  {
    name: 'user-store',
    storage: createJSONStorage(() => localStorage),
  },
),
);

export default useUserStore;