import AuthLayout from '../../modules/auth/components/AuthLayout';
import UpdatePasswordForm from '../../modules/auth/components/UpdatePasswordForm';

// UpdatePasswordPage is used to set new password after reset password verification, different from user setting screen
const UpdatePasswordPage = () => {
  return (
    <AuthLayout header="Update Password" title="Enter New Password">
      <UpdatePasswordForm />
    </AuthLayout>
  );
};

export default UpdatePasswordPage;
