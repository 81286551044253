import { Button, Card, Dropdown, Flex, Grid, Space, Tag, Tooltip, Typography } from 'antd';
import { AiOutlineEdit } from 'react-icons/ai';
import { useToggle } from '../../../shared/hooks/useToggle';
import { useRef } from 'react';
import { handleCopyProfileUrl } from '../utils/handleCopyProfileUrl';
import { generateProfilePDF } from '../utils/generateProfilePdf';
import UploadAvatar from '../../../shared/components/UploadAvatar';
import UploadCover from '../../../shared/components/UploadCover';
import { ReactComponent as Logo } from '../../../assets/svgs/angel-logo-white.svg';
import { b64toBlob } from '../../../shared/utils/files';
import { useUpdateInvestorProfile } from '../hooks/useUpdateInvestorProfile';
import InvestorHeaderEditModal from './InvestorHeaderEditModal';
import { useUpdateInvestorProfilePrivacy } from '../hooks/useUpdateInvestorProfilePrivacy';
import { getInvestorName } from '../utils/getInvestorName';
import { toast } from 'react-toastify';
import Avatar from 'src/shared/components/Avatar';
import InvestorMakeProfilePrivateModal from './InvestorMakeProfilePrivateModal';
import FollowButton from './FollowButton';

interface Props {
  canUpdate: boolean;
  profilePhoto?: string;
  coverPhoto?: string;
  firstName?: string;
  lastName?: string;
  handle?: string;
  country?: string;
  investorStatus?: string;
  mentor?: string;
  numberOfFollowers?: number;
  isPrivateProfile?: boolean;
  isFollowing?: boolean;
  isProfileOwner?: boolean;
  id?: string;
  refetch: () => void;
}

const InvestorHeadingSection = ({
  canUpdate,
  profilePhoto,
  coverPhoto,
  firstName,
  lastName,
  handle,
  country,
  investorStatus,
  mentor,
  numberOfFollowers,
  isPrivateProfile,
  isFollowing,
  isProfileOwner,
  id,
  refetch,
}: Props) => {
  const { md } = Grid.useBreakpoint();

  const { open: editProfileModalOpen, onClose: onCloseEditModal, onOpen: onOpenEditModal } = useToggle();
  const { open: privacyModalOpen, onClose: onClosePrivacyModal, onOpen: onOpenPrivacyModal } = useToggle();
  const { handleUpdateInvestorProfile } = useUpdateInvestorProfile({ onCompleted: refetch });
  const formInitialValuesRef = useRef({});

  const investorName = getInvestorName(firstName, lastName);

  const onInvestorProfilePrivacyUpdated = () => {
    refetch();
    onClosePrivacyModal();
  };
  const { handleUpdateInvestorProfilePrivacy } = useUpdateInvestorProfilePrivacy({
    onCompleted: onInvestorProfilePrivacyUpdated,
  });

  const handleChangePrivacy = () => {
    if (isPrivateProfile) {
      handleUpdateInvestorProfilePrivacy({ isPrivateProfile: false });
    }
    else {
      onOpenPrivacyModal();
    }
  };

  const handleChangeProfilePicture = (data: string | null) => {
    if (!data) {
      toast.error('Profile photo not found, please try again.');
      return;
    }

    const formData = new FormData();
    formData.append('profilePhoto', b64toBlob(data));
    handleUpdateInvestorProfile(formData);
  };

  const handleChangeCoverPhoto = (data: string | null) => {
    if (!data) {
      toast.error('Cover photo not found, please try again.');
      return;
    }

    const formData = new FormData();
    formData.append('coverPhoto', b64toBlob(data));
    handleUpdateInvestorProfile(formData);
  };

  const items = [
    {
      label: (
        <Button size="small" type="text" onClick={() => handleCopyProfileUrl(handle, 'investor')}>
          Copy profile link
        </Button>
      ),
      key: 'copy',
    },
    {
      label: (
        <Button size="small" type="text" onClick={() => generateProfilePDF({ name: investorName })}>
          Download page as pdf
        </Button>
      ),
      key: 'download-pdf',
    },
    {
      label: (
        <Button size="small" type="text" onClick={handleChangePrivacy}>
          {isPrivateProfile ? 'Make profile public' : 'Make profile private'}
        </Button>
      ),
      key: 'set-private',
    },
    ...(handle
      ? [
          {
            label: (
              <Button size="small" type="text" href={`/investor/${handle}`} target="_blank">
                View as visitor
              </Button>
            ),
            key: 'view-as-visitor',
          },
        ]
      : []),
  ];

  const handleOpenEditModal = () => {
    onOpenEditModal();
    formInitialValuesRef.current = {
      firstName,
      lastName,
      country,
      handle,
      investorStatus,
      mentor,
    };
  };

  const handleCompleted = () => {
    refetch();
    onCloseEditModal();
  };

  return (
    <>
      <InvestorHeaderEditModal
        open={editProfileModalOpen}
        onClose={onCloseEditModal}
        initialValues={formInitialValuesRef.current}
        onCompleted={handleCompleted}
      />

      <InvestorMakeProfilePrivateModal
        open={privacyModalOpen}
        onClose={onClosePrivacyModal}
        onCompleted={onInvestorProfilePrivacyUpdated}
      />

      <Card
        cover={
          <div className="tw-relative">
            {canUpdate ? (
              <UploadCover width={996} height={360} onChange={handleChangeCoverPhoto} file={coverPhoto} />
            ) : (
              <>
                {coverPhoto ? (
                  <img
                    alt="example"
                    src={coverPhoto}
                    className="tw-object-cover tw-max-h-[360px] tw-bg-center tw-bg-no-repeat tw-rounded-t-xl tw-w-full tw-h-full"
                  />
                ) : (
                  <div className="tw-bg-background-black tw-text-center tw-rounded-t-xl">
                    <Logo
                      style={{
                        height: '30vw',
                        width: '35vw',
                        maxHeight: '360px',
                      }}
                    />
                  </div>
                )}
              </>
            )}
            <div className="tw-absolute md:tw-bottom-[-46px] lg:tw-bottom-[-54px] tw-bottom-[-108px] sm:tw-pl-3 tw-pl-6">
              {canUpdate ? (
                <UploadAvatar
                  file={profilePhoto}
                  size={md ? 153 : 92}
                  role="investor"
                  onChange={handleChangeProfilePicture}
                  className="tw-border-4 tw-border-text-white"
                />
              ) : (
                <Avatar
                  role="investor"
                  src={profilePhoto}
                  size={md ? 153 : 92}
                  className="tw-border-4 tw-border-text-white"
                />
              )}
            </div>
          </div>
        }
      >
        <Flex justify="space-between" gap={8} align={md ? 'center' : 'start'}>
          <Space size={12} direction="vertical">
            <div className="tw-space-y-3 tw-pl-40 md:tw-pl-0">
              <div className="tw-space-y-1 md:tw-pt-11">
                <Typography.Title level={4} className="tw-mb-0 tw-font-semibold tw-text-text-primary">
                  {getInvestorName(firstName, lastName)}
                </Typography.Title>

                <Typography.Text className="tw-block tw-text-xs tw-text-text-body">
                  {country} {mentor === 'Yes' && '· Mentor to Founders'}
                </Typography.Text>

                {investorStatus && (
                  <Tag className="tw-text-xs tw-text-text-primary tw-px-4 tw-py-1">{investorStatus}</Tag>
                )}
              </div>

              <Button type="link" className="tw-h-6 tw-p-0 tw-text-primary-500" size="small">
                {numberOfFollowers} {numberOfFollowers === 1 ? 'follower' : 'followers'}
              </Button>
            </div>
          </Space>

          <Flex gap={8} align="center">
            {canUpdate ? (
              <>
                <Dropdown menu={{ items }} placement="bottomLeft">
                  <Button type="default" size="small">
                    More
                  </Button>
                </Dropdown>

                <Tooltip title="Edit profile">
                  <Button type="default" size="small" onClick={handleOpenEditModal}>
                    <AiOutlineEdit size={20} />
                  </Button>
                </Tooltip>
              </>
            ) : (
              <>
                <FollowButton
                  profileId={id}
                  profileType="investor"
                  isFollowing={isFollowing}
                  isProfileOwner={isProfileOwner}
                  refetch={refetch}
                />
                <Button type="default" size="small" onClick={() => generateProfilePDF({ name: investorName })}>
                  Download page
                </Button>

                <Button type="default" size="small" onClick={() => handleCopyProfileUrl(handle, 'investor')}>
                  Copy profile link
                </Button>
              </>
            )}
          </Flex>
        </Flex>
      </Card>
    </>
  );
};

export default InvestorHeadingSection;
