import { useEffect, useState } from 'react';
import { Row, Col, Spin } from 'antd';
import ProfileCard from '../components/ProfileCard';
import useApiFetch from 'src/shared/hooks/useApiFetch';
import SyndicateService from 'src/api/services/profiles/syndicate.service';
import { BasePaginationParams, BaseResponse } from 'src/api/common';

const SyndicateList = () => {
  const [syndicates, setSyndicates] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [scrollToBottom, setScrollToBottom] = useState(false);

  const rowsPerPage = 12;

  const { loading } = useApiFetch<BasePaginationParams, BaseResponse & { data: any }>(
    SyndicateService.getAllSyndicates,
    {
      page,
      limit: rowsPerPage,
    },
    {
      onSuccess(response) {
        setSyndicates(prevSyndicates => [...prevSyndicates, ...response?.data?.syndicates]);
        setTotalPages(response?.data?.pagination?.totalPages);
      },
    },
  );

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = document.documentElement?.scrollTop || document.body.scrollTop;
      const scrollHeight = document.documentElement?.scrollHeight || document.body.scrollHeight;
      const clientHeight = document.documentElement.clientHeight || window.innerHeight;
      const scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;

      if (scrolledToBottom && !loading) {
        setScrollToBottom(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [loading]);

  useEffect(() => {
    if (scrollToBottom && page <= totalPages) {
      setPage(page + 1);
    }
  }, [scrollToBottom, totalPages, page]);

  return (
    <Spin spinning={loading}>
      {syndicates?.length ? (
        <Row className="card-profiles-row mt-5 mb-5" gutter={[24, 24]}>
          {syndicates?.map((syndicate, index) => (
            <Col xxl={4} xl={6} lg={8} md={12} sm={12} xs={24} key={index} className="mb-4">
              <ProfileCard
                role={'syndicate'}
                handle={syndicate?.handle}
                name={syndicate?.name}
                profilePhoto={syndicate?.profilePhoto}
                country={syndicate?.country}
              />
            </Col>
          ))}
        </Row>
      ) : (
        <div className="d-flex justify-content-center align-items-center mt-5 mb-4">No syndicates found</div>
      )}
    </Spin>
  );
};

export default SyndicateList;
