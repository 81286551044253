import { Flex, Skeleton } from 'antd';
import useApiFetch from '../../../shared/hooks/useApiFetch';
import { GetProfileParams, ProfileResponse } from '../../../api/services/profiles/profile.dto';
import PageTitle from '../../../shared/components/PageTitle';
import HeadingSectionPrivate from './HeadingSectionPrivate';
import SyndicateService from '../../../api/services/profiles/syndicate.service';
import PostList from './PostList';
import SyndicateHeadingSection from './SyndicateHeadingSection';
import SyndicateGeneralSection from './SyndicateGeneralSection';
import SyndicateIntroSection from './SyndicateIntroSection';
import ErrorSection from 'src/shared/components/ErrorSection';

interface Props {
  handle?: string;
}

const SyndicateProfile = ({ handle }: Props) => {
  const {
    data: syndicate,
    loading,
    error,
    status,
    refetch,
  } = useApiFetch<GetProfileParams, ProfileResponse>(SyndicateService.getSyndicateProfile, {
    id: handle,
  });

  if (error) {
    // Users cannot create syndicates like other profiles, provide special instructions
    if (status === 404) {
      return <ErrorSection error="Syndicate not found. Contact hello@angeflow.vc to create your syndicate profile." />;
    }
    return <ErrorSection error={error} />;
  }

  const canUpdate = !handle;

  const isPrivate = !canUpdate && syndicate?.isPrivate;

  return (
    <div id="section" className="tw-w-full tw-max-w-[996px] tw-min-w-4/5">
      <Skeleton loading={loading}>
        <PageTitle title={syndicate?.name} />

        {isPrivate ? (
          <HeadingSectionPrivate
            role="syndicate"
            profilePhoto={syndicate?.profilePhoto}
            coverPhoto={syndicate?.coverPhoto}
            name={syndicate?.name}
          />
        ) : (
          <Flex vertical gap={24} className="py-8 px-24">
            <SyndicateHeadingSection
              canUpdate={canUpdate}
              profilePhoto={syndicate?.profilePhoto}
              coverPhoto={syndicate?.coverPhoto}
              name={syndicate?.name}
              handle={syndicate?.handle}
              country={syndicate?.country}
              isPrivate={syndicate?.isPrivate}
              investorStatus={syndicate?.investorStatus}
              refetch={refetch}
            />

            <SyndicateGeneralSection data={syndicate} canUpdate={canUpdate} refetch={refetch} />

            <SyndicateIntroSection
              about={syndicate?.about}
              interestCategories={syndicate?.interestCategories}
              investmentStages={syndicate?.investmentStages}
              canUpdate={canUpdate}
              refetch={refetch}
            />

            <PostList posts={syndicate?.posts} canUpdate={canUpdate} refetch={refetch} />
          </Flex>
        )}
      </Skeleton>
    </div>
  );
};

export default SyndicateProfile;
