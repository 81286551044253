import { Typography } from 'antd';

interface ErrorSectionProps {
  error: string;
}

// TODO Will enhance this component in the future
// TODO Add call to action, e.g. contact support
/**
 * ErrorSection component displays an error message.
 *
 * @param {{ error: string }} props
 * @returns {React.ReactElement}
 */
const ErrorSection = ({ error }: ErrorSectionProps): React.ReactElement => {
  return (
    <div className="tw-text-center tw-text-red-500 tw-mt-4">
      <Typography.Text>{error}</Typography.Text>
    </div>
  );
};

export default ErrorSection;
