import { AuthError, AuthOtpResponse, AuthResponse, OAuthResponse, Provider, UserResponse } from '@supabase/supabase-js';
import supabase from '../../../modules/auth/utils/supabase';
import APIClient from '../../client';
import {
  ChangePasswordDto,
  ResetPasswordDto,
  LoginDto,
  OldLoginResponse,
  RegisterDto,
  RegisterResponse,
  SendVerificationEmailDto,
  UpdatePasswordDto,
  VerifyOTPDto,
  ResetPasswordStatusResponse,
} from './auth.dto';
import { AUTH_ROUTES } from './auth.routes';
import AUTH_PATHS from '../../../routes/auth.paths';
import { BaseResponse } from 'src/api/common';

export default class AuthService {
  #emailRedirectTo;

  #resetPasswordEmailRedirectTo;

  constructor() {
    if (!process.env.REACT_APP_BASE_URL) {
      throw new Error('Base url is not defined');
    }
    // Backend will add /:result
    this.#emailRedirectTo = `${process.env.REACT_APP_BASE_URL}${AUTH_PATHS.VERIFY_RESULT.replace('/:result', '')}`;

    this.#resetPasswordEmailRedirectTo = `${process.env.REACT_APP_BASE_URL}${AUTH_PATHS.RESET_PASSWORD_RESULT.replace('/:result', '')}`;
  }

  public async register(email: string, password: string): Promise<AuthResponse> {
    return await supabase.auth.signUp({ email, password, options: { emailRedirectTo: this.#emailRedirectTo } });
  }

  public async login(email: string, password: string): Promise<AuthResponse> {
    return await supabase.auth.signInWithPassword({ email, password });
  }

  public async loginSocial(provider: Provider): Promise<OAuthResponse> {
    return await supabase.auth.signInWithOAuth({ provider });
  }

  public async resetPassword(email: string): Promise<BaseResponse> {
    return APIClient.post({
      route: AUTH_ROUTES.RESET_PASSWORD,
      data: { email, redirectTo: this.#resetPasswordEmailRedirectTo },
    });
  }

  public async setSession(accessToken: string, refreshToken: string): Promise<AuthResponse> {
    return await supabase.auth.setSession({ access_token: accessToken, refresh_token: refreshToken });
  }

  public async updatePassword(password: string): Promise<UserResponse> {
    return await supabase.auth.updateUser({ password });
  }

  public async logout(): Promise<{ error: AuthError | null }> {
    return await supabase.auth.signOut();
  }

  public async resendEmailSignUpConfirmation(email: string): Promise<AuthOtpResponse> {
    return await supabase.auth.resend({
      type: 'signup',
      email,
      options: {
        emailRedirectTo: this.#emailRedirectTo,
      },
    });
  }
}

// TODO Old, remove
export const OldAuthService = {
  login: (data: LoginDto): Promise<OldLoginResponse> =>
    APIClient.post({ route: AUTH_ROUTES.LOGIN, data, isPublic: true }),
  register: (data: RegisterDto): Promise<RegisterResponse> =>
    APIClient.post({ route: AUTH_ROUTES.REGISTER, data, isPublic: true }),
  sendVerificationEmail: (data: SendVerificationEmailDto) =>
    APIClient.post({ route: AUTH_ROUTES.SEND_VERIFICATION_EMAIL, data, isPublic: true }),
  resetPassword: (data: ResetPasswordDto) =>
    APIClient.post({ route: AUTH_ROUTES.RESET_PASSWORD, data, isPublic: true }),
  getResetPasswordStatus: (email: string): Promise<ResetPasswordStatusResponse> =>
    APIClient.get({ route: `${AUTH_ROUTES.CHECK_RESET_PASSWORD_STATUS}/${email}` }),
  updatePassword: (data: UpdatePasswordDto): Promise<BaseResponse> =>
    APIClient.post({ route: AUTH_ROUTES.UPDATE_PASSWORD, data }),
  changePassword: (data: ChangePasswordDto) => APIClient.post({ route: AUTH_ROUTES.CHANGE_PASSWORD, data }),
  emailVerification: () => APIClient.post({ route: AUTH_ROUTES.VERIFY_EMAIL }),
  sendOTP: () => APIClient.post({ route: AUTH_ROUTES.SEND_OTP }),
  verifyOTP: (data: VerifyOTPDto) => APIClient.post({ route: AUTH_ROUTES.VERIFY_OTP, data }),
  otpStatus: () => APIClient.get({ route: AUTH_ROUTES.OTP_STATUS }),
};
