import StartupProfile from '../modules/profiles/components/StartupProfile';
import { useParams } from 'react-router-dom';
import PrivateLayout from '../shared/components/PrivateLayout';

const StartupViewPage = () => {
  const { handle } = useParams();

  return (
    <PrivateLayout>
      <StartupProfile handle={handle} />
    </PrivateLayout>
  );
};

export default StartupViewPage;
