import { Button, Flex, Modal, Space, Typography } from 'antd';
import { useDeleteInvestment } from '../hooks/useDeleteInvestment';

interface Props {
  open: boolean;
  investmentId: string;
  onClose: () => void;
  onCompleted: () => void;
}

const DeleteInvestmentModal = ({ open, investmentId, onClose, onCompleted }: Props) => {
  const { handleDeleteInvestment, loading } = useDeleteInvestment({
    onCompleted: () => {
      onClose();
      onCompleted();
    },
  });

  const onDelete = () => {
    handleDeleteInvestment(investmentId);
  };

  return (
    <Modal
      centered
      closeIcon={null}
      open={open}
      onCancel={onClose}
      destroyOnClose
      width={520}
      footer={null}
      title={null}
    >
      <Space direction="vertical" className="tw-w-full" size={16}>
        <div className="tw-text-center">
          <Typography.Title level={3} className="tw-text-text-primary tw-font-semibold tw-text-xl">
            Remove investment
          </Typography.Title>
          <Typography.Text className="tw-text-text-body tw-text-base tw-block">
            Are you sure you want to remove this investment ?
          </Typography.Text>
          <Typography.Text className="tw-text-text-body tw-text-base tw-block">This action cannot be undone.</Typography.Text>
        </div>

        <Flex gap={12} className="tw-w-full tw-pt-4">
          <Button size="middle" onClick={onClose} className="tw-w-1/2">
            Cancel
          </Button>
          <Button type="primary" size="middle" onClick={onDelete} loading={loading} className="tw-w-1/2">
            Remove
          </Button>
        </Flex>
      </Space>
    </Modal>
  );
};

export default DeleteInvestmentModal;
