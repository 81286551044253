import { Flex } from 'antd';

interface Props {
  step: number;
  totalSteps: number;
}

const StepDots = ({ step, totalSteps }: Props) => {
  return (
    <Flex align="center" justify="center" className="tw-absolute tw-right-8 tw-top-16" gap={8}>
      {[...Array(totalSteps).fill(0)].map((_, index) => (
        <div
          key={`progress-dot-${index}`}
          className={`${index <= Number(step) ? 'tw-bg-primary-500' : 'tw-bg-primary-50'} tw-h-2 tw-w-2 tw-rounded-full`}
        />
      ))}
    </Flex>
  );
};

export default StepDots;
