// @ts-ignore
import Sidebar from '../../components/Sidebar';
import { Flex, Grid } from 'antd';

interface Props {
  children: React.ReactNode;
}

const PrivateLayout = ({ children }: Props) => {
  const { lg } = Grid.useBreakpoint();

  return (
    <Sidebar>
      <div className={lg ? 'tw-pl-[270px]' : ''}>
        <Flex vertical align="center" className="tw-w-full tw-py-8">
          {children}
        </Flex>
      </div>
    </Sidebar>
  );
};

export default PrivateLayout;
