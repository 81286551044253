import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

export type Role = 'investor' | 'startup' | 'syndicate';

interface RoleState {
  currentRole?: Role;
  roles: Role[];
}

interface RoleAction {
  setCurrentRole: (role: Role) => void;
  setRoles: (roles: Role[]) => void;
  addRole: (role: Role) => void;
  clearRoles: () => void;
}

const useRoleStore = create(
  persist<RoleState & RoleAction>(
    set => ({
      roles: [],
      setCurrentRole: (currentRole: Role) => set({ currentRole }),
      setRoles: (roles: Role[]) => set({ roles }),
      addRole: (role: Role) => set(state => ({ roles: [...state.roles, role] })),
      clearRoles: () => set({ roles: [], currentRole: undefined }),
    }),
    {
      name: 'role-store',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
export default useRoleStore;
