import { useParams } from 'react-router-dom';
import PrivateLayout from '../shared/components/PrivateLayout';
import InvestorProfile from '../modules/profiles/components/InvestorProfile';

const InvestorViewPage = () => {
  const { handle } = useParams();

  return (
    <PrivateLayout>
      <InvestorProfile handle={handle} />
    </PrivateLayout>
  );
};

export default InvestorViewPage;
