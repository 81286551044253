import { useState } from 'react';
import { Row, Col, Spin, Flex } from 'antd';
import ProfileCard from '../components/ProfileCard';
import useApiFetch from 'src/shared/hooks/useApiFetch';
import { BaseResponse } from 'src/api/common';
import ProfileService from 'src/api/services/profiles/profile.service';
import useRoleStore from 'src/shared/stores/role.store';
import { GetFollowersParams } from 'src/api/services/profiles/profile.dto';
import useInfiniteScroll from 'src/shared/hooks/useInfiniteScroll';

const rowsPerPage = 12;

const FollowerList = () => {
  const { currentRole } = useRoleStore();
  const [followers, setFollowers] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const { loading } = useApiFetch<GetFollowersParams, BaseResponse & { data: any }>(
    ProfileService.getFollowers,
    {
      page,
      limit: rowsPerPage,
      role: String(currentRole),
    },
    {
      onSuccess(response) {
        setFollowers(prevFollowers => [...prevFollowers, ...response?.data?.followerProfiles]);
        setTotalPages(response?.data?.pagination?.totalPages);
      },
    },
  );

  useInfiniteScroll(loading, () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  });

  return (
    <>
      {followers?.length ? (
        <Row className="tw-mt-5 tw-mb-5" gutter={[24, 24]}>
          {followers?.map((follower, index) => (
            <Col xxl={4} xl={6} lg={8} md={12} sm={12} xs={24} key={index} className="mb-4">
              <ProfileCard
                role={follower?.role}
                handle={follower?.handle || follower?.user?.handle}
                name={follower?.companyName || `${follower?.firstName} ${follower?.lastName}`}
                profilePhoto={follower?.profilePhoto}
                country={follower?.country}
              />
            </Col>
          ))}
        </Row>
      ) : (
        <>
          {!loading && (
            <Flex className="tw-mt-5 tw-mb-4" justify="center" align="center">
              No followers found
            </Flex>
          )}
        </>
      )}
      <Spin size="large" className="tw-w-full tw-min-h-16" spinning={loading} />
    </>
  );
};

export default FollowerList;
