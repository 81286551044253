import AuthService, { OldAuthService } from '../../../api/services/auth/auth.service';
import { LoginDto, OldLoginResponse } from '../../../api/services/auth/auth.dto';
import useAuthStore from '../stores/auth.store';
import useUserStore from '../../../shared/stores/user.store';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useConfigStore } from '../../../shared/stores/config.store';
import UserService from '../../../api/services/user/user.service';
import { UserCurrentResponse } from '../../../api/services/user/user.dto';
import PROFILE_PATHS from 'src/routes/profile.paths';
import useRoleStore from 'src/shared/stores/role.store';

export const useLoginForm = () => {
  const authService = new AuthService();
  const { login, loading, setLoading } = useAuthStore();
  const { featureFlags } = useConfigStore();
  const { setRoles, setCurrentRole } = useRoleStore();
  const { setUser } = useUserStore();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const handleLogin = async ({ email, password, isRememberMe }: LoginDto) => {
    try {
      setLoading(true);
      let user: (OldLoginResponse | UserCurrentResponse)['data'];
      if (featureFlags.supabaseAuth) {
        const { data, error } = await authService.login(email, password);
        if (error || !data.session) {
          // Request resend email confirmation from supabase if email not confirmed
          if (error?.code === 'email_not_confirmed') {
            await authService.resendEmailSignUpConfirmation(email);
            toast.warning('Email not confirmed. Check your email to confirm.');
            return;
          }
          throw error ?? new Error('Session not found');
        }
        // Store token and set isAuthenticated
        login(data.session.access_token, isRememberMe);
        // Retrieve user details
        const response = await new UserService().getUserCurrent();
        if (!response.success) {
          throw new Error(response.message);
        }
        user = response.data;
      }
      else {
        const response = await OldAuthService.login({ email, password });
        if (!response.success) {
          throw new Error(response.message);
        }
        login(response.data.token, isRememberMe);
        user = response.data;
      }
      // Store user details
      const {
        userId,
        firstName,
        lastName,
        profilePhoto,
        companyName,
        companyProfilePhoto,
        syndicateName,
        syndicateProfilePhoto,
      } = user;
      setUser({
        userId,
        firstName,
        lastName,
        email,
        profilePhoto,
        companyName,
        companyProfilePhoto,
        syndicateName,
        syndicateProfilePhoto,
      });
      if (user.role) {
        setRoles(user.role);
        const role = user.role.includes('investor') ? 'investor' : 'startup';
        setCurrentRole(role);
      }

      const redirect = searchParams.get('redirect');
      if (redirect) {
        navigate(redirect);
      }
      else if (!user?.role) {
        navigate(PROFILE_PATHS.SETUP);
      }
      else {
        navigate('/');
      }
    }
    catch (error) {
      console.error(error);
      toast.error((error as Error)?.message);
    }
    finally {
      setLoading(false);
    }
  };

  return {
    handleLogin,
    loading,
  };
};
