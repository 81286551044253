import { useEffect, useState } from 'react';
import useAuth from './modules/auth/hooks/useAuth';
import { useConfigStore } from './shared/stores/config.store.ts';
import ConfigService from './api/services/config.service';
import { ToastContainer, toast } from 'react-toastify';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { PublicRoutes } from './routes/public.routes';
import AUTH_PATHS from './routes/auth.paths';
import RoleProvider from './context/RoleProvider';
import VerifyResultPage from './views/auth/VerifyResultPage';
import { PrivateRoutes } from './routes/private.routes';
import PrivateRoute from './routes/PrivateRoute';
import ErrorPage from './components/ErrorPage';
import ProfileSetupPage from './views/profile-setup/ProfileSetupPage';
import ResetPasswordResultPage from './views/auth/ResetPasswordResultPage';
import PROFILE_PATHS from './routes/profile.paths';
import { Flex, Spin } from 'antd';

const App = () => {
  const [loading, setLoading] = useState(true);
  useAuth();
  const { featureFlags, setConfig } = useConfigStore();

  useEffect(() => {
    const getFeatureFlags = async () => {
      try {
        const { featureFlags } = await ConfigService.getAllFeatureFlags();
        setConfig({ featureFlags });
      }
      catch (error) {
        console.error(error);
        // This error likely means the server is unreachable, so give generic error message
        toast.error('Error, see console for details');
      }
      finally {
        setLoading(false);
      }
    };
    getFeatureFlags();
  }, [setConfig]);

  // Render a loading state while the feature flags are being fetched
  if (loading) {
    return (
      <Flex className="tw-w-screen tw-h-screen" justify="center" align="center">
        <Spin size="large" />
      </Flex>
    );
  }

  return (
    <div data-testid="app">
      <ToastContainer />
      <Router>
        <RoleProvider>
          <Routes>
            {PublicRoutes?.map((item, index) => (
              <Route key={index} path={item.path} element={<item.component />} />
            ))}

            {featureFlags.supabaseAuth && (
              <>
                <Route path={AUTH_PATHS.RESET_PASSWORD_RESULT} element={<ResetPasswordResultPage />} />
                <Route path={AUTH_PATHS.VERIFY_RESULT} element={<VerifyResultPage />} />
                <Route path={AUTH_PATHS.ERROR} element={<ErrorPage />} />
              </>
            )}

            {PrivateRoutes?.map((item, index) => (
              <Route
                key={index}
                path={item.path}
                element={<PrivateRoute component={item.component} path={item.path} />}
              />
            ))}
            <Route
              path={PROFILE_PATHS.SETUP}
              element={<PrivateRoute path={PROFILE_PATHS.SETUP} component={ProfileSetupPage} />}
            />
            <Route path="*" element={<ErrorPage notFound={true} />} />
          </Routes>
        </RoleProvider>
      </Router>
    </div>
  );
};

export default App;
