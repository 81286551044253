/**
 * Axios Request Wrapper
 * ---------------------
 *
 * @author  Sheharyar Naseer (@sheharyarn)
 * @license MIT
 *
 * TODO Deprecated, remove
 */

import axios from 'axios';
import { config } from '../config';
import useAuthStore from '../modules/auth/stores/auth.store';
import useUserStore from '../shared/stores/user.store';
import useRoleStore from '../shared/stores/role.store';

/**
 * Create an Axios Client with defaults
 */

const client = axios.create({
  baseURL: config?.BaseURL,
});

client.interceptors.request.use(req => {
  if (req?.token) {
    req.headers.Authorization = `Bearer ${req?.token}`;
  }
  return req;
});

client.interceptors.response.use(
  response => response,
  error => Promise.reject(error),
);

/**
 * Request Wrapper with default success/error actions
 */
const request = options => {
  const onSuccess = response => {
    return response.data;
  };

  const onError = error => {
    if (error.response) {
      if (error.response.status === 401) {
        // TODO Attempt to refresh token and retry the original request, for now let's just log out the user to force re-login
        useAuthStore.getState().logout();
        useUserStore.getState().clearUser();
        useRoleStore.getState().clearRoles();
        window.location.href = '/login';
      }
    }
    else {
      // Something else happened while setting up the request
      // triggered the error
    }

    return Promise.reject(error.response || error.message);
  };

  return client(options).then(onSuccess).catch(onError);
};

export default request;
