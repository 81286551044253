import { useState, useEffect, startTransition } from 'react';
import useAuthStore from '../stores/auth.store';
import { toast } from 'react-toastify';
import { getToken } from '../utils/token';
import AUTH_PATHS from '../../../routes/auth.paths';

  // TODO Move to config
const TIMEOUT_IN_MS = 3600000;

const useInactivityTimer = () => {
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const { logout } = useAuthStore();

  const startInactivityTimer = () => {
    return setTimeout(() => {
      setIsSessionExpired(true);
    }, TIMEOUT_IN_MS);
  };

  useEffect(() => {
    let inactivityTimer = startInactivityTimer();

    function resetTimer() {
      clearTimeout(inactivityTimer);
      inactivityTimer = startInactivityTimer();
    }

    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('keypress', resetTimer);

    return () => {
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('keypress', resetTimer);
      clearTimeout(inactivityTimer);
    };
  }, []);

  useEffect(() => {
    // TODO Check path rather than token (shouldn't redirect on public routes)
    const token = getToken();
    if (token && isSessionExpired) {
      startTransition(() => {
        logout();
        toast.info('Your session has expired. Please login again.');
        // TODO Toast doesn't display, move hook inside router and use useNavigate
        // TODO Get current path and add as redirect
        window.location.href = AUTH_PATHS.LOGIN;
      });
    }
  }, [isSessionExpired, logout]);
};

export default useInactivityTimer;
