import { Button, Col, Flex, Form, Input, Modal, Row, Typography } from 'antd';
import AngelLogo from 'src/shared/components/AngelLogo';
import CountrySelect from 'src/modules/profile-setup/components/CountrySelect';
import IndustrySelect from 'src/modules/profile-setup/components/IndustrySelect';
import { useStartupSetupForm } from 'src/modules/profile-setup/hooks/useStartupSetupForm';
import { Pattern } from 'src/shared/utils/validators';
import { config } from 'src/config';

interface Props {
  open: boolean;
  onCompleted: () => void;
  onClose: () => void;
}

const StartupCreateModal = ({ open, onCompleted, onClose }: Props) => {
  const [form] = Form.useForm();
  const { handleSubmit, loading } = useStartupSetupForm({ onCompleted });

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      centered
      open={open}
      closeIcon={null}
      onCancel={handleClose}
      destroyOnClose
      width={740}
      title={null}
      footer={null}
    >
      <div className="tw-w-full tw-space-y-10 tw-px-4 tw-py-5">
        <div className="tw-space-y-8">
          <AngelLogo />
          <div>
            <Typography.Title level={2} className="tw-mb-2 tw-text-[40px] tw-font-bold tw-text-text-primary">
              Create Startup Profile
            </Typography.Title>
          </div>
        </div>

        <Form form={form} layout="vertical" size="large" className="tw-space-y-10" onFinish={handleSubmit}>
          <Row gutter={[16, 0]}>
            <Col span={24}>
              <Form.Item
                label="Company name"
                name="companyName"
                rules={[{ required: true, message: 'Please enter your company name' }]}
              >
                <Input placeholder="Enter company name" />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Handle"
                name="handle"
                rules={[
                  { required: true, whitespace: true, message: 'Please enter a handle for your company' },
                  {
                    pattern: Pattern.profileCustomUrl,
                    message: 'Special characters are not allowed',
                  },
                ]}
              >
                <Input
                  addonBefore={`${config?.AppBaseURL}/startup/`}
                  placeholder="Enter a url for your company profile"
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Country"
                name="country"
                rules={[{ required: true, message: 'Please select your country' }]}
              >
                <CountrySelect showSearch />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item label="Founder(s)" name="founder">
                <Input placeholder="Enter company founder(s)" />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item name="interestCategories" label="Industries">
                <IndustrySelect placeholder="Select industries" mode="multiple" maxTagCount={2} allowClear />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <Flex align="center" justify="space-between" gap={12}>
          <Button className="tw-w-1/2" type="default" size="large" onClick={handleClose}>
            Cancel
          </Button>
          <Button className="tw-w-1/2" type="primary" size="large" onClick={form.submit} loading={loading}>
            Save
          </Button>
        </Flex>
      </div>
    </Modal>
  );
};

export default StartupCreateModal;
