import { Select, SelectProps } from 'antd';
import { INVESTMENT_RANGES } from 'src/shared/constants/investmentRanges';

const investmentRangeSelectOptions = INVESTMENT_RANGES.map(investmentRange => ({
  value: investmentRange,
  label: investmentRange,
}));

const InvestmentRangeSelect = ({ ...rest }: SelectProps) => {
  return <Select options={investmentRangeSelectOptions} {...rest} />;
};

export default InvestmentRangeSelect;
