/**
 * Returns the container element for popups.
 *
 * @param {HTMLElement | undefined} node - The HTML element to find the container for.
 * @param {string[]} [queries=['.ant-modal', '.ant-drawer']] - The list of CSS selectors to query for the container.
 * @returns {HTMLElement} - The container element for the popup.
 */
export const getPopupContainer = (
  node: HTMLElement | undefined,
  queries = ['.ant-modal', '.ant-drawer'],
) => {
  const parent = document.querySelector(
    queries.find((e) => document.querySelector(e)) as string,
  );
  if (node && parent?.contains(node)) return node.parentNode as HTMLElement;
  return document.body;
};

/**
 * Converts a given hexadecimal color to an RGBA color string.
 *
 * @param {string} color - The hexadecimal color to be converted.
 * @param {number} opacity - The opacity value of the RGBA color.
 * @return {string} The RGBA color string.
 */
export const convertHexToRgba = (color: string, opacity: number): string => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
  return result
    ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${opacity})`
    : `rgba(0, 0, 0, ${opacity})`; // Default to black if invalid color
}

