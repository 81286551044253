import APIClient from '../../client';
import { UserCurrentResponse } from './user.dto';

const ROUTES = {
  GET_USER_CURRENT: '/api/users/current',
};

export default class UserService {
  public getUserCurrent = (): Promise<UserCurrentResponse> => APIClient.get({ route: ROUTES.GET_USER_CURRENT });
}
