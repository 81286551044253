import { Select, SelectProps } from 'antd';
import { DEAL_TYPES } from 'src/shared/constants/dealTypes';

const dealTypeSelectOptions = DEAL_TYPES.map(dealType => ({
  value: dealType,
  label: dealType,
}));

const DealTypeSelect = ({ ...rest }: SelectProps) => {
  return <Select options={dealTypeSelectOptions} {...rest} />;
};

export default DealTypeSelect;
