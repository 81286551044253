import InvestorService from '../../../api/services/profiles/investor.service';
import { useState } from 'react';
import { toast } from 'react-toastify';

interface Props {
  onCompleted: () => void;
}

export const useUpdateInvestorProfilePrivacy = ({ onCompleted }: Props) => {
  const [loading, setLoading] = useState(false);

  const handleUpdateInvestorProfilePrivacy = async ({ isPrivateProfile }: { isPrivateProfile: boolean }) => {
    try {
      setLoading(true);

      const response = await InvestorService.updateInvestorProfile({
        isPrivateProfile,
      });

      if (response?.success) {
        onCompleted();
        toast.success(isPrivateProfile ? 'Profile set to private' : 'Profile set to public');
      }
      else {
        toast.error(response?.message);
      }
    } 
    catch (error) {
      toast.error((error as Error)?.message);
    }
    finally {
      setLoading(false);
    }
  };

  return {
    handleUpdateInvestorProfilePrivacy,
    loading,
  };
};
