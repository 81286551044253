import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import useAuthStore from 'src/modules/auth/stores/auth.store';
import AUTH_PATHS from 'src/routes/auth.paths';

type VerifyParams = { result: 'success' | 'error' };
// status corresponds to HTTP status code, see Supabase docs
type VerifyQueryString = { token: string; status: string; message: string };

/**
 * Shows result of email verification
 *
 * User clicks a verify link in email which sends verify request to backend. Backend then redirects to here with either success or error result param
 *
 * TODO Apply layout & theme
 */
const VerifyResultPage = () => {
  const { result } = useParams<VerifyParams>();
  const { token, status, message } = JSON.parse(useLocation().search) as VerifyQueryString;
  const navigate = useNavigate();
  const { logout } = useAuthStore();

  useEffect(() => {
    if (result === 'success') {
      // TODO: for noew let's just redirect to login, will find a way to get correct token and set to store later
      logout();
      // Success, redirect to home page after 3 seconds
      setTimeout(() => {
        navigate(AUTH_PATHS.LOGIN);
      }, 3000);
    }
  }, [result, token, navigate, logout]);

  return (
    <div>
      {result === 'success' ? (
        <p>Verified successfully, close this window or wait to be redirected</p>
      ) : (
        <p>
          Verification failed: {status} - {message}. Please contact support.
        </p>
      )}
    </div>
  );
};

export default VerifyResultPage;
