import { Button, Flex, Modal, Space, Typography } from 'antd';
import { useUpdateStartupProfilePrivacy } from '../hooks/useUpdateStartupProfilePrivacy';
import { ReactComponent as LockIcon } from '../../../assets/svgs/lock.svg';

interface Props {
  open: boolean;
  onClose: () => void;
  onCompleted: () => void;
}

const StartupMakeProfilePrivateModal = ({ open, onClose, onCompleted }: Props) => {
  const { handleUpdateStartupProfilePrivacy, loading } = useUpdateStartupProfilePrivacy({ onCompleted });

  const handleUpdate = () => {
    handleUpdateStartupProfilePrivacy({ isPrivateProfile: true });
  };

  return (
    <Modal
      centered
      maskClosable={false}
      open={open}
      onCancel={onClose}
      destroyOnClose
      width={490}
      footer={null}
      title={null}
    >
      <Space direction="vertical" className="tw-w-full" size={16}>
        <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
          <LockIcon />
        </div>

        <div className="tw-text-center">
          <Typography.Title level={3} className="tw-text-text-primary tw-font-semibold tw-text-xl">
            Make profile private
          </Typography.Title>
          <Typography.Text className="tw-text-text-body tw-text-base">
            Are you sure you want to make your profile private? Your profile information will be hidden from other
            users.
          </Typography.Text>
        </div>

        <Flex gap={12} className="tw-w-full tw-pt-4">
          <Button size="middle" onClick={onClose} className="tw-w-1/2">
            Cancel
          </Button>
          <Button type="primary" size="middle" onClick={handleUpdate} loading={loading} className="tw-w-1/2">
            Confirm
          </Button>
        </Flex>
      </Space>
    </Modal>
  );
};

export default StartupMakeProfilePrivateModal;
