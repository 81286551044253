import { INDUSTRIES } from '../../../shared/constants/industries';
import { Select, SelectProps } from 'antd';

const interestCategorySelectOptions = INDUSTRIES.map(industry => ({
  value: industry,
  label: industry,
}));

const IndustrySelect = ({ ...rest }: SelectProps) => {
  return <Select options={interestCategorySelectOptions} {...rest} />;
};

export default IndustrySelect;
