import { Col, Divider, Form, Input, Modal, Row } from 'antd';
import { useUpdateStartupProfile } from '../hooks/useUpdateStartupProfile';
import CountrySelect from '../../profile-setup/components/CountrySelect';
import { config } from '../../../config';
import { Pattern } from '../../../shared/utils/validators';

interface Props {
  open: boolean;
  initialValues?: any;
  onClose: () => void;
  onCompleted: () => void;
}

const StartupHeaderEditModal = ({ open, initialValues, onClose, onCompleted }: Props) => {
  const [form] = Form.useForm();
  const { handleSubmit, loading } = useUpdateStartupProfile({ onCompleted });

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      destroyOnClose
      cancelButtonProps={{ hidden: true }}
      okButtonProps={{ loading, size: 'small' }}
      width={740}
      title={
        <>
          Edit Profile
          <Divider />
        </>
      }
      okText="Save"
      onOk={form.submit}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        size="small"
        onFinish={handleSubmit}
      >
        <Row gutter={[12, 0]}>
          <Col span={24}>
            <Form.Item
              label="Handle"
              name="handle"
              rules={[
                { required: true, whitespace: true, message: 'Please enter a handle for your company' },
                {
                  pattern: Pattern.profileCustomUrl,
                  message: 'Special characters are not allowed',
                },
              ]}
            >
              <Input
                addonBefore={`${config?.AppBaseURL}/startup/`}
                placeholder="Enter a url for your company profile"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Company name"
              name="companyName"
              rules={[{ required: true, whitespace: true, message: 'Please enter your company name' }]}
            >
              <Input placeholder="Enter your company's name" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Country"
              name="country"
              rules={[{ required: true, message: 'Please select your country' }]}
            >
              <CountrySelect placeholder="Select your company's country" showSearch />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item label="Company elevator pitch" name="companyProfile">
              <Input placeholder="Enter your company's elevator pitch" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default StartupHeaderEditModal;
