import LoginPage from '../views/auth/LoginPage';
import SignUpPage from '../views/auth/SignUpPage';
import ResetPasswordPage from '../views/auth/ResetPasswordPage';
import UpdatePasswordPage from '../views/auth/UpdatePasswordPage';
import VerifyEmail from '../views/auth/verifyEmail';
import EmailVerification from '../views/auth/verification';
import AUTH_PATHS from './auth.paths';

export const PublicRoutes = [
  {
    title: 'Signup',
    component: SignUpPage,
    path: AUTH_PATHS.SIGNUP,
  },
  {
    title: 'Login',
    component: LoginPage,
    path: AUTH_PATHS.LOGIN,
  },
  {
    title: 'Reset Password',
    component: ResetPasswordPage,
    path: AUTH_PATHS.RESET_PASSWORD,
  },
  {
    title: 'Verify Email',
    component: VerifyEmail,
    path: AUTH_PATHS.SEND_EMAIL,
  },
  {
    title: 'Email Verification',
    component: EmailVerification,
    path: AUTH_PATHS.VERIFY_EMAIL,
  },
  {
    title: 'Update Password',
    component: UpdatePasswordPage,
    path: AUTH_PATHS.UPDATE_PASSWORD,
  },
];
