import { Typography } from 'antd';

interface Props {
  content: string;
  rows?: number;
}

/**
 * Paragraph component renders a paragraph that is expandable to show the full content.
 *
 * @param {{ content: string, rows?: number }} props
 * @returns {React.ReactElement}
 *
 * @example
 * <Paragraph content={content} rows={3} />
 *
 * If children is a string, it will be rendered as a Typography.Paragraph.
 * Otherwise, it will be rendered as a div with the given children.
 */
const Paragraph = ({ content, rows = 3 }: Props): React.ReactElement => {
  return (
    <Typography.Paragraph
      ellipsis={{
        rows,
        expandable: 'collapsible',
        symbol: expanded => <span className="tw-font-medium tw-text-text-primary">{expanded ? 'Less' : ' Read More'}</span>,
      }}
      className="tw-text-sm tw-text-text-body"
    >
      {content}
    </Typography.Paragraph>
  );
};

export default Paragraph;
