import type { OverrideToken } from 'antd/es/theme/interface';
import { COLOR } from '../constants';

export const BUTTON: OverrideToken['Button'] = {
  borderColorDisabled: COLOR.border.disabled,
  borderRadius: 12,
  borderRadiusLG: 12,
  borderRadiusSM: 12,
  colorLink: COLOR.primary[500],
  colorLinkHover: COLOR.primary[400],
  colorText: COLOR.text.primary,
  colorPrimaryText: COLOR.text.white,
  colorPrimaryHover: COLOR.primary[500],
  textHoverBg: 'transparent',
  colorTextBase: COLOR.text.primary,
  controlHeight: 50,
  controlHeightLG: 56,
  controlHeightSM: 38,
  defaultBg: COLOR.background.white,
  defaultBorderColor: COLOR.border.black,
  defaultColor: COLOR.text.primary,
  groupBorderColor: 'unset',
  fontSizeSM: 14,
  contentFontSizeSM: 14,
  linkHoverBg: 'tranparent',
  paddingBlock: 8,
  paddingInline: 12,
  primaryColor: COLOR.text.white,
  primaryShadow: 'unset',
  colorPrimary: COLOR.primary[500],
};
