import { Button, Flex, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import Avatar from 'src/shared/components/Avatar';
import { Role } from 'src/shared/stores/role.store';

interface Props {
  data: any;
  type: Role;
}

const FeedsProfileCard = ({ data, type }: Props) => {
  const navigate = useNavigate();

  const handleViewProfile = () => {
    navigate(`/${type}/${data?.handle}`, {
      state: { data: data?.id },
    });
  };

  return (
    <Flex gap={20}>
      <div className="tw-max-w-[120px] tw-min-h-[110px] tw-max-h-[110px] tw-w-full">
        <Avatar shape="square" role={type} src={data?.profilePhoto} size={118} />
      </div>
      <Flex vertical justify="space-between" className="tw-w-full">
        <div>
          <Typography.Paragraph
            ellipsis={{ rows: 1 }}
            className="tw-text-base tw-font-bold tw-mb-1 tw-text-text-primary"
          >
            {data?.companyName
              ? data?.companyName
              : data?.firstName
                ? data?.firstName + ' ' + data?.lastName
                : 'No Name'}
          </Typography.Paragraph>
          <Typography.Paragraph className="tw-text-sm tw-text-text-gray tw-mb-0 tw-mr-4" ellipsis={{ rows: 2 }}>
            {data?.about || data?.aboutCompany}
          </Typography.Paragraph>
        </div>

        <Flex justify="end" className="tw-mt-2">
          <Button type="link" className="tw-underline tw-h-0" onClick={handleViewProfile}>
            View Profile
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default FeedsProfileCard;
