import { Col, Divider, Form, Input, Modal, Row } from 'antd';
import { useUpdateInvestorProfile } from '../hooks/useUpdateInvestorProfile';
import IndustrySelect from '../../profile-setup/components/IndustrySelect';
import InvestmentStageSelect from '../../profile-setup/components/InvestmentStageSelect';

interface Props {
  open: boolean;
  initialValues?: any;
  onCompleted: () => void;
  onClose: () => void;
}

const InvestorIntroEditModal = ({ open, initialValues, onCompleted, onClose }: Props) => {
  const [form] = Form.useForm();
  const { handleUpdateInvestorProfile, loading } = useUpdateInvestorProfile({ onCompleted });

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      destroyOnClose
      cancelButtonProps={{ hidden: true }}
      okButtonProps={{ loading, size: 'small' }}
      width={740}
      title={
        <>
          Edit Introduction
          <Divider />
        </>
      }
      okText="Save"
      onOk={form.submit}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        size="small"
        onFinish={handleUpdateInvestorProfile}
      >
        <Row gutter={[12, 0]}>
          <Col span={24}>
            <Form.Item name="about" label="About">
              <Input.TextArea
                placeholder="Tell others a little bit more about yourself - this can anything about your investment experience or what you're looking for in a startup."
                autoSize={{ minRows: 6 }}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item name="interestCategories" label="Interested industries">
              <IndustrySelect placeholder="Select industry" mode="multiple" maxTagCount={2} allowClear />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item name="investmentStages" label="Investment stages">
              <InvestmentStageSelect
                placeholder="Select the stage of investment in which you wish to invest"
                mode="multiple"
                maxTagCount={2}
                allowClear
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default InvestorIntroEditModal;
