import { Typography } from 'antd';

interface Props {
  label: string;
  children?: React.ReactNode | string;
}

/**
 * Description component renders a label and value pair.
 *
 * @param {{ label: string, children?: React.ReactNode | string }} props
 * @returns {React.ReactElement}
 *
 * @example
 * <Description label="Name">{user.name}</Description>
 * <Description label="Age">{user.age}</Description>
 *
 * If children is a string, it will be rendered as a Typography.Paragraph.
 * Otherwise, it will be rendered as a div with the given children.
 */
const Description = ({ label, children }: Props): React.ReactElement => {
  return (
    <div className="tw-space-y-0">
      <Typography.Text className="tw-text-sm tw-text-text-subTitle">{label}</Typography.Text>
      {typeof children === 'string' ? (
        <Typography.Paragraph className="tw-text-sm tw-font-medium tw-text-text-body">
          {children || '--'}
        </Typography.Paragraph>
      ) : (
        <>{children ? <div>{children}</div> : <Typography.Paragraph className="tw-text-sm">--</Typography.Paragraph>}</>
      )}
    </div>
  );
};

export default Description;
