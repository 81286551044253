import React from 'react';
import { RiArrowDownSLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import useAuthStore from '../modules/auth/stores/auth.store';
import AUTH_PATHS from '../routes/auth.paths';
import PROFILE_PATHS from '../routes/profile.paths';
import PATHS from '../routes/paths.ts';
import useRoleStore from '../shared/stores/role.store';
import useUserStore from '../shared/stores/user.store';
import Avatar from '../shared/components/Avatar';
import { Typography, Dropdown } from 'antd';
import { uppercaseFirstLetter } from '../shared/utils/strings';
import { useToggle } from '../shared/hooks/useToggle';
import InvestorCreateModal from '../modules/profiles/components/InvestorCreateModal';
import StartupCreateModal from '../modules/profiles/components/StartupCreateModal';

const ProfileSwitcher = () => {
  const { logout } = useAuthStore();
  const { clearUser } = useUserStore();
  const navigate = useNavigate();
  const {
    open: createInvestorModalOpen,
    onClose: onCloseCreateInvestorModal,
    onOpen: onOpenCreateInvestorModal,
  } = useToggle();
  const {
    open: createStartupModalOpen,
    onClose: onCloseCreateStartupModal,
    onOpen: onOpenCreateStartupModal,
  } = useToggle();

  const { roles, currentRole, setCurrentRole, clearRoles } = useRoleStore();
  const { firstName, lastName, profilePhoto, companyName, companyProfilePhoto, syndicateName, syndicateProfilePhoto } =
    useUserStore();
  const userName = firstName && lastName ? `${firstName} ${lastName}` : '';

  const roleMappings = {
    investor: {
      name: userName,
      avatar: profilePhoto,
      path: PROFILE_PATHS.INVESTOR_YOUR,
    },
    startup: {
      name: companyName,
      avatar: companyProfilePhoto,
      path: PROFILE_PATHS.STARTUP_YOUR,
    },
    syndicate: {
      name: syndicateName,
      avatar: syndicateProfilePhoto,
      path: PROFILE_PATHS.SYNDICATE_YOUR,
    },
  };

  const currentName = roleMappings[currentRole]?.name;
  const currentAvatar = roleMappings[currentRole]?.avatar;

  const handleLogout = () => {
    logout();
    clearUser();
    clearRoles();
    navigate(AUTH_PATHS.LOGIN);
  };

  const handleChangeRole = role => () => {
    if (!roles.includes(role)) {
      if (role === 'startup') {
        onOpenCreateStartupModal();
        return;
      }
      if (role === 'investor') {
        onOpenCreateInvestorModal();
        return;
      }
      return;
    }

    setCurrentRole(role);
    navigate(PATHS.HOME);
  };

  const onInvestorCreated = () => {
    onCloseCreateInvestorModal();
    setCurrentRole('investor');
    navigate(PROFILE_PATHS.INVESTOR_YOUR);
  };

  const onStartupCreated = () => {
    onCloseCreateStartupModal();
    setCurrentRole('startup');
    navigate(PROFILE_PATHS.STARTUP_YOUR);
  };

  const menuItems = [
    ...(Object.keys(roleMappings)?.map(role => {
      if (role === currentRole) {
        return null;
      }
      // Hide 'create syndicate'
      if (role === 'syndicate' && !roles.includes(role)) {
        return null;
      }

      const label = !roles.includes(role)
        ? `Create ${uppercaseFirstLetter(role)} profile`
        : `Switch to ${uppercaseFirstLetter(role)}`;
      return {
        key: role,
        label: <Typography.Text className="tw-text-sm tw-text-text-body">{label}</Typography.Text>,
        onClick: handleChangeRole(role),
      };
    }) ?? []),
    {
      label: <Typography.Text className="tw-text-sm tw-text-text-body">Profile View</Typography.Text>,
      key: 'profile-view',
      onClick: () => navigate(roleMappings[currentRole]?.path || PATHS.HOME),
    },
    {
      label: <Typography.Text className="tw-text-sm tw-text-text-body">Update Password</Typography.Text>,
      key: 'update-password',
      onClick: () => navigate(PATHS.CHANGE_PASSWORD),
    },
    {
      label: <Typography.Text className="tw-text-sm tw-text-text-body">Logout</Typography.Text>,
      key: 'logout',
      onClick: handleLogout,
    },
  ];

  return (
    <div className="profile-dropdown d-flex flex-row gap-2 align-items-center">
      <Avatar src={currentAvatar} className="tw-flex-shrink-0" size={56} role={currentRole} />
      <div className="owner-profile d-flex  flex-column gap-1">
        <Typography.Title
          level={4}
          className="tw-text-base tw-max-w-24 tw-truncate tw-font-medium tw-text-text-primary tw-mb-0"
        >
          {currentName}
        </Typography.Title>
        <Dropdown className="settings-dropdown tw-min-w-[150px]" trigger={['click']} menu={{ items: menuItems }}>
          <div className="tw-flex tw-gap-2 tw-items-center tw-cursor-pointer ">
            <span className="tw-text-text-subTitle">{uppercaseFirstLetter(currentRole)}</span>
            <RiArrowDownSLine className="arrow-down" />
          </div>
        </Dropdown>
      </div>
      <InvestorCreateModal
        open={createInvestorModalOpen}
        onClose={onCloseCreateInvestorModal}
        onCompleted={onInvestorCreated}
      />
      <StartupCreateModal
        open={createStartupModalOpen}
        onClose={onCloseCreateStartupModal}
        onCompleted={onStartupCreated}
      />
    </div>
  );
};

export default ProfileSwitcher;
