import { useState } from 'react';
import { Row, Col, Spin, Flex } from 'antd';
import ProfileCard from '../components/ProfileCard';
import useApiFetch from 'src/shared/hooks/useApiFetch';
import StartupService from 'src/api/services/profiles/startup.service';
import { BasePaginationParams, BaseResponse } from 'src/api/common';
import useInfiniteScroll from 'src/shared/hooks/useInfiniteScroll';

const rowsPerPage = 12;

const StartupList = () => {
  const [startups, setStartups] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const { loading } = useApiFetch<BasePaginationParams, BaseResponse & { data: any }>(
    StartupService.getAllStartups,
    {
      page,
      limit: rowsPerPage,
    },
    {
      onSuccess(response) {
        setStartups(prevStartups => [...prevStartups, ...response?.data?.startups]);
        setTotalPages(response?.data?.pagination?.totalPages);
      },
    },
  );

  useInfiniteScroll(loading, () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  });

  return (
    <>
      {startups?.length ? (
        <Row className="tw-mt-5 tw-mb-5" gutter={[24, 24]}>
          {startups?.map((startup, index) => (
            <Col xxl={4} xl={6} lg={8} md={12} sm={12} xs={24} key={index} className="mb-4">
              <ProfileCard
                role={'startup'}
                handle={startup?.handle}
                name={startup?.companyName}
                profilePhoto={startup?.profilePhoto}
                country={startup?.country}
              />
            </Col>
          ))}
        </Row>
      ) : (
        <>
          {!loading && (
            <Flex className="tw-mt-5 tw-mb-4" justify="center" align="center">
              No startups found
            </Flex>
          )}
        </>
      )}
      <Spin size="large" className="tw-w-full tw-min-h-16" spinning={loading} />
    </>
  );
};

export default StartupList;
