import { Card, Flex, Grid, Space, Typography } from 'antd';
import { ReactComponent as Logo } from '../../../assets/svgs/angel-logo-white.svg';
import { Role } from 'src/shared/stores/role.store';
import Avatar from 'src/shared/components/Avatar';
import Empty from 'src/shared/components/Empty';

interface Props {
  profilePhoto?: string;
  coverPhoto?: string;
  name?: string;
  role: Role;
}

const HeadingSectionPrivate = ({ profilePhoto, coverPhoto, name, role }: Props) => {
  const { md } = Grid.useBreakpoint();

  return (
    <>
      <Card
        cover={
          <div className="tw-relative">
            {coverPhoto ? (
              <img
                alt="example"
                src={coverPhoto}
                className="tw-bg-contain tw-bg-center tw-bg-no-repeat tw-rounded-t-xl tw-w-full tw-h-full"
              />
            ) : (
              <div className="tw-bg-background-black tw-text-center tw-rounded-t-xl">
                <Logo
                  style={{
                    height: '30vw',
                    width: '35vw',
                    maxHeight: '360px',
                  }}
                />
              </div>
            )}
            <div className="tw-absolute md:tw-bottom-[-46px] lg:tw-bottom-[-54px] tw-bottom-[-108px] sm:tw-pl-3 tw-pl-6">
              <Avatar
                role={role}
                src={profilePhoto}
                size={md ? 153 : 92}
                className="tw-border-4 tw-border-text-white"
              />
            </div>
          </div>
        }
      >
        <div className="tw-space-y-3">
          <Flex justify="space-between" gap={8} align={md ? 'center' : 'start'}>
            <Space size={12}>
              <div className="tw-space-y-1 md:tw-pt-11 tw-pl-40 md:tw-pl-0">
                <Typography.Title level={4} className="tw-mb-0 tw-font-semibold tw-text-text-primary">
                  {name}
                </Typography.Title>
              </div>
            </Space>
          </Flex>
        </div>

        <Empty title="This profile is private" />
      </Card>
    </>
  );
};

export default HeadingSectionPrivate;
