import { BasePaginationParams } from 'src/api/common';
import APIClient from '../../client';
import { CreateStartupDto, GetProfileParams, ProfileResponse } from './profile.dto';

const STARTUP_ENDPOINTS = {
  GET_ALL_STARTUPS: '/api/startups',
  GET_STARTUP_PROFILE: '/api/startup',
  CREATE_STARTUP: '/api/startups',
  UPDATE_STARTUP_PROFILE: '/api/startup-profile',
};

const StartupService = {
  getAllStartups: ({ page, limit }: BasePaginationParams): Promise<any> =>
    APIClient.get({ route: `${STARTUP_ENDPOINTS.GET_ALL_STARTUPS}?page=${page ?? 1}&limit=${limit ?? 10}` }),
  getStartupProfile: ({ id, currentRole }: GetProfileParams = {}): Promise<ProfileResponse> => {
    const url = currentRole
      ? `${STARTUP_ENDPOINTS.GET_STARTUP_PROFILE}/${currentRole}/${id ?? ''}`
      : `${STARTUP_ENDPOINTS.GET_STARTUP_PROFILE}/${id ?? ''}`;
    return APIClient.get({ route: url });
  },
  createStartup: (data: CreateStartupDto | FormData): Promise<ProfileResponse> =>
    APIClient.post({ route: STARTUP_ENDPOINTS.CREATE_STARTUP, data }),
  updateStartupProfile: (data: any): Promise<ProfileResponse> =>
    APIClient.put({ route: STARTUP_ENDPOINTS.UPDATE_STARTUP_PROFILE, data }),
};

export default StartupService;
