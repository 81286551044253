import APIClient from '../../client';
import { BasePaginationParams, BaseResponse } from '../../common';
import { GetProfileParams, ProfileResponse } from './profile.dto';
import { UpdateSyndicateDto } from './syndicate.dto';

const SYNDICATE_ENDPOINTS = {
  GET_SYNDICATES: '/api/syndicates',
  GET_SYNDICATE_PROFILE: '/api/syndicate',
  UPDATE_SYNDICATE_PROFILE: '/api/syndicate',
};

const SyndicateService = {
  getAllSyndicates: ({ page, limit }: BasePaginationParams): Promise<any> => APIClient.get({ route: `${SYNDICATE_ENDPOINTS.GET_SYNDICATES}?page=${page ?? 1}&limit=${limit ?? 10}` }),

  getSyndicateProfile: ({ id }: GetProfileParams = {}): Promise<ProfileResponse> => {
    return APIClient.get({ route: `${SYNDICATE_ENDPOINTS.GET_SYNDICATE_PROFILE}/${id ?? ''}` });
  },

  updateSyndicateProfile: (data: UpdateSyndicateDto): Promise<BaseResponse> =>
    APIClient.put({ route: SYNDICATE_ENDPOINTS.UPDATE_SYNDICATE_PROFILE, data }),
};

export default SyndicateService;
