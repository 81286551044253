import { INVESTMENT_STAGES } from "../../../shared/constants/investmentStages";
import { Select, SelectProps } from "antd";

const investmentStageSelectOptions = INVESTMENT_STAGES.map((stage) => ({
  value: stage,
  label: stage,
}))

const InvestmentStageSelect = ({...rest}: SelectProps) => {
  return (
    <Select
      options={investmentStageSelectOptions}
      {...rest}
    />
  );
};

export default InvestmentStageSelect;
