/**
 * @function getInvestorName
 * @description Returns a full name given a first and last name.
 * If either first or last name is missing, returns the available one.
 * @param {string} firstName - The first name.
 * @param {string} lastName - The last name.
 * @returns {string} The full name.
 */
export const getInvestorName = (firstName?: string, lastName?: string): string => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }
  if (firstName) {
    return firstName;
  }
  return lastName || '';
};
