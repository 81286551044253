import APIClient from '../client';

const ROUTES = {
  GET_ALL_FEATURE_FLAGS: '/config/feature-flags',
};

const ConfigService = {
  getAllFeatureFlags: () => APIClient.get({ route: ROUTES.GET_ALL_FEATURE_FLAGS }),
};

export default ConfigService;
