import { create } from 'zustand';

type FeatureFlags = Record<string, boolean>;

interface ConfigState {
  featureFlags: FeatureFlags;
}

interface ConfigActions {
  setConfig: (config: ConfigState) => void;
  clearConfig: () => void;
}

export const useConfigStore = create<ConfigState & ConfigActions>(set => ({
  featureFlags: {},
  setConfig: (config: ConfigState) => set({ ...config }),
  clearConfig: () => set({ featureFlags: {} }),
}));
