import { Button, Checkbox, Form } from 'antd';
import { Role } from 'src/shared/stores/role.store';

interface Props {
  onSubmit: ({ roles }: { roles: Role[] }) => void;
}

const SelectRoleForm = ({ onSubmit }: Props) => {
  return (
    <Form className="tw-space-y-10" size="large" onFinish={onSubmit}>
      <Form.Item initialValue={[]} name="roles" rules={[{ required: true, message: 'Please select your role(s)' }]}>
        <Checkbox.Group className="tw-flex tw-gap-10">
          <Checkbox value="investor">Investor</Checkbox>
          <Checkbox value="startup">Startup</Checkbox>
        </Checkbox.Group>
      </Form.Item>

      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => (
          <Button
            disabled={getFieldValue('roles')?.length === 0}
            htmlType="submit"
            type="primary"
            className="tw-w-full"
          >
            Save
          </Button>
        )}
      </Form.Item>
    </Form>
  );
};

export default SelectRoleForm;
