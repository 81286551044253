import { Button, Col, Flex, Form, Input, Modal, Row, Typography } from 'antd';
import AngelLogo from 'src/shared/components/AngelLogo';
import CountrySelect from 'src/modules/profile-setup/components/CountrySelect';
import IndustrySelect from 'src/modules/profile-setup/components/IndustrySelect';
import { useInvestorSetupForm } from 'src/modules/profile-setup/hooks/useInvestorSetupForm';
import InvestmentStageSelect from 'src/modules/profile-setup/components/InvestmentStageSelect';

interface Props {
  open: boolean;
  onCompleted: () => void;
  onClose: () => void;
}

const InvestorCreateModal = ({ open, onCompleted, onClose }: Props) => {
  const [form] = Form.useForm();
  const { handleUpdateInvestorProfile, loading } = useInvestorSetupForm({ onCompleted });

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      centered
      open={open}
      closeIcon={null}
      onCancel={handleClose}
      destroyOnClose
      width={740}
      title={null}
      footer={null}
    >
      <div className="tw-w-full tw-space-y-10 tw-px-4 tw-py-5">
        <div className="tw-space-y-8">
          <AngelLogo />
          <div>
            <Typography.Title level={2} className="tw-mb-2 tw-text-[40px] tw-font-bold tw-text-text-primary">
              Create Investor Profile
            </Typography.Title>
          </div>
        </div>

        <Form
          form={form}
          layout="vertical"
          size="large"
          className="tw-space-y-10"
          onFinish={handleUpdateInvestorProfile}
        >
          <Row gutter={[16, 0]}>
            <Col md={24} xl={12} xs={24}>
              <Form.Item
                label="First Name"
                name="firstName"
                rules={[{ required: true, message: 'Please enter your first name' }]}
              >
                <Input placeholder="Enter first name" />
              </Form.Item>
            </Col>

            <Col md={24} xl={12} xs={24}>
              <Form.Item
                label="Last Name"
                name="lastName"
                rules={[{ required: true, message: 'Please enter your last name' }]}
              >
                <Input placeholder="Enter last name" />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Country"
                name="country"
                rules={[{ required: true, message: 'Please select your country' }]}
              >
                <CountrySelect showSearch />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item label="Investment Stages" name="investmentStages">
                <InvestmentStageSelect
                  placeholder="Select investment stages"
                  mode="multiple"
                  maxTagCount={2}
                  allowClear
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item name="interestCategories" label="Interested Industries">
                <IndustrySelect placeholder="Select interested industries" mode="multiple" maxTagCount={2} allowClear />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <Flex align="center" justify="space-between" gap={12}>
          <Button className="tw-w-1/2" type="default" size="large" onClick={handleClose}>
            Cancel
          </Button>
          <Button className="tw-w-1/2" type="primary" size="large" onClick={form.submit} loading={loading}>
            Save
          </Button>
        </Flex>
      </div>
    </Modal>
  );
};

export default InvestorCreateModal;
