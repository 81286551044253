import { Card, Flex, Image, Skeleton, Space, Typography } from 'antd';
import dayjs from 'dayjs';
import Avatar from '../../../shared/components/Avatar';
import { Role } from '../../../shared/stores/role.store';

interface Props {
  user: {
    name: string;
    avatar?: string;
  };
  role: Role;
  createdAt?: string;
  title: string;
  image?: string;
  description: string;
  loading?: boolean;
}

const PostCard = ({ user, role, createdAt, image, title, description, loading }: Props) => {
  if (loading) {
    return (
      <Card bordered={false} className="tw-max-w-[720px] tw-w-full">
        <Space direction="vertical" size={12} className="tw-w-full">
          <Flex justify="center" align="center" className="tw-w-full tw-bg-background-gray tw-rounded-xl">
            <Skeleton.Image active className="tw-w-full tw-h-96" />
          </Flex>

          <Flex gap={8}>
            <Skeleton.Avatar size={48} active />

            <Space direction="vertical" size={12}>
              <Skeleton.Input active className="tw-h-6" />
              <Skeleton.Input active className="tw-h-3" />
            </Space>
          </Flex>

          <Skeleton active paragraph={{ rows: 3 }} title={{ className: 'tw-h-6' }} />
        </Space>
      </Card>
    );
  }

  return (
    <Card bordered={false} className="tw-max-w-[720px] tw-w-full">
      <Space direction="vertical" size={12} className="tw-w-full">
        {image && (
          <Flex justify="center" align="center" className="tw-w-full tw-bg-background-gray tw-rounded-xl">
            <Image src={image} />
          </Flex>
        )}

        <Flex gap={8} align="center">
          <div className="tw-flex-shrink-0">
            <Avatar role={role} src={user?.avatar} size={48} />
          </div>

          <Space direction="vertical" size={0}>
            <Typography.Text className="tw-text-text-primary tw-text-sm tw-font-semibold tw-mb-0">
              {user.name}
            </Typography.Text>
            <Typography.Text className="tw-text-text-subTitle tw-text-xs">
              {dayjs(createdAt).format('DD MMM YYYY [at] HH:mm')}
            </Typography.Text>
          </Space>
        </Flex>

        <Space direction="vertical" size={8}>
          <Typography.Title level={5} className="tw-font-semibold">
            {title}
          </Typography.Title>
          <Typography.Paragraph className="tw-text-sm tw-text-text-body">{description}</Typography.Paragraph>
        </Space>
      </Space>
    </Card>
  );
};

export default PostCard;
