const AUTH_PATHS = {
  SIGNUP: '/signup',
  LOGIN: '/login',
  VERIFY_RESULT: '/auth/verify/:result',
  RESET_PASSWORD_RESULT: '/auth/reset-password/:result',
  UPDATE_PASSWORD: '/update-password',
  RESET_PASSWORD: '/reset-password',
  // Old routes
  VERIFY_EMAIL: '/:token/verify-email',
  SEND_EMAIL: '/send-email',
};

export default AUTH_PATHS;
