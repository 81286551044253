import { Divider, Form, Modal } from 'antd';
import { UpdateInvestmentDto } from 'src/api/services/investment/investment.dto';
import { useUpdateInvestment } from '../hooks/useUpdateInvestment';
import InvestmentForm from './InvestmentForm';

interface Props {
  open: boolean;
  initialValues?: any;
  onCompleted: () => void;
  onClose: () => void;
}

const UpdateInvestmentModal = ({ open, initialValues, onCompleted, onClose }: Props) => {
  const [form] = Form.useForm<UpdateInvestmentDto>();

  const { handleUpdateInvestment, loading } = useUpdateInvestment({
    investmentId: initialValues?.id,
    onCompleted() {
      onClose();
      onCompleted();
    },
  });

  const handleSubmit = (data: UpdateInvestmentDto) => {
    handleUpdateInvestment({
      ...data,
      dealSize: data.dealSize ?? null,
      companyStage: data.companyStage ?? null,
      dealType: data.dealType ?? null,
      industry: data.industry ?? null,
    });
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      destroyOnClose
      cancelButtonProps={{ hidden: true }}
      okButtonProps={{ loading, size: 'small' }}
      okText="Save"
      onOk={form.submit}
      width={732}
      title={
        <>
          Edit an investment
          <Divider />
        </>
      }
    >
      <Form form={form} initialValues={initialValues} layout="vertical" size="small" onFinish={handleSubmit}>
        <InvestmentForm />
      </Form>
    </Modal>
  );
};

export default UpdateInvestmentModal;
