import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import AuthService, { OldAuthService } from '../../../api/services/auth/auth.service';
// @ts-ignore
import OldApi from '../../../api-old/api.js';
import { SetPasswordDto } from '../../../api/services/auth/auth.dto';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AUTH_PATHS from 'src/routes/auth.paths';
import { useConfigStore } from 'src/shared/stores/config.store';
import { jwtDecode } from 'jwt-decode';
import { isTokenExpired, removeToken, setToken, getToken } from '../utils/token';

export const useUpdatePasswordForm = () => {
  const authService = new AuthService();
  const navigate = useNavigate();
  const { featureFlags } = useConfigStore();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);

  const handleCheckPasswordUpdated = async (token: string) => {
    try {
      const response = await OldApi.getIsPasswordUpdated(token);
      if (response.success) {
        if (response?.isPasswordUpdated) {
          toast.error('This link has been used to update your password once.');
          navigate(AUTH_PATHS.RESET_PASSWORD);
        }
      }
    }
    catch (error) {
      console.error((error as Error).message);
    }
  };

  useEffect(() => {
    const token = searchParams.get('token');
    if (token && !featureFlags.supabaseAuth) {
      const decodedAuthToken = jwtDecode(token);
      if (isTokenExpired(decodedAuthToken.exp as number)) {
        toast.error('Link has expired. Please request another link');
        navigate(AUTH_PATHS.RESET_PASSWORD);
      }
      else {
        setToken(token);
        handleCheckPasswordUpdated(token);
      }
    }
  }, [navigate, featureFlags]);

  const handleSubmit = async ({ password, confirmPassword }: SetPasswordDto) => {
    try {
      setLoading(true);

      if (featureFlags.supabaseAuth) {
        const response = await authService.updatePassword(password);

        if (response.error) {
          throw new Error(response.error.message);
        }

        toast.success('Please login with your new password.');
        authService.logout();
        navigate(AUTH_PATHS.LOGIN);
      }
      else {
        // Old update password API logic
        const token = getToken();
        if (token) {
          // Old api update password requires token, but should not use useAuth to login, simply setToken and clear after this request
          const response = await OldAuthService.updatePassword({
            password,
            confirmPassword,
          });
          if (response.success) {
            toast.success(response.message);
            navigate('/');
          }
          else {
            toast.error(response.message);
          }
          removeToken();
        }
      }
    }
    catch (error) {
      toast.error((error as Error).message);
    }
    finally {
      setLoading(false);
    }
  };

  return {
    handleSubmit,
    loading,
  };
};
