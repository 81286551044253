import { Col, DatePicker, Form, Input, Row } from 'antd';
import { LuCalendarDays } from 'react-icons/lu';
import { CreateInvestmentDto } from 'src/api/services/investment/investment.dto';
import IndustrySelect from 'src/modules/profile-setup/components/IndustrySelect';
import DealTypeSelect from './DealTypeSelect';
import InvestmentStageSelect from 'src/modules/profile-setup/components/InvestmentStageSelect';
import InvestmentRangeSelect from './InvestmentRangeSelect';

const InvestmentForm = () => {
  return (
    <Row>
      <Col span={24}>
        <Form.Item<CreateInvestmentDto>
          label="Company name"
          name="companyName"
          rules={[{ required: true, whitespace: true, message: 'Please enter company name' }]}
        >
          <Input placeholder="Enter company name" />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item<CreateInvestmentDto> label="Deal date" name="dealDate">
          <DatePicker
            className="tw-w-full"
            picker="month"
            placeholder="Select deal date"
            format="MM/YYYY"
            suffixIcon={<LuCalendarDays size={18} className="tw-text-text-primary" />}
            allowClear
          />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item<CreateInvestmentDto> label="Company stage" name="companyStage">
          <InvestmentStageSelect showSearch placeholder="Select company stage" allowClear />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item<CreateInvestmentDto>
          label="Deal size"
          name="dealSize"
        >
          <InvestmentRangeSelect placeholder="Select range of your investment" allowClear />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item<CreateInvestmentDto> label="Lead investor" name="leadInvestor">
          <Input placeholder="Enter lead investor" />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item<CreateInvestmentDto> label="Deal type" name="dealType">
          <DealTypeSelect showSearch placeholder="Select deal type" allowClear />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item<CreateInvestmentDto> label="Industry" name="industry">
          <IndustrySelect placeholder="Select industry" allowClear />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default InvestmentForm;
