import { Col, DatePicker, Divider, Form, Input, Modal, Row } from 'antd';
import { useUpdateStartupProfile } from '../hooks/useUpdateStartupProfile';
import dayjs from 'dayjs';
import { Pattern } from '../../../shared/utils/validators';
import { LuCalendarDays } from 'react-icons/lu';
import { COLOR } from '../../../theme/constants';

interface Props {
  open: boolean;
  initialValues?: any;
  onCompleted: () => void;
  onClose: () => void;
}

const StartupGeneralEditModal = ({ open, initialValues, onCompleted, onClose }: Props) => {
  const [form] = Form.useForm();
  const { handleSubmit, loading } = useUpdateStartupProfile({ onCompleted });

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      destroyOnClose
      cancelButtonProps={{ hidden: true }}
      okButtonProps={{ loading, size: 'small' }}
      width={740}
      title={
        <>
          Edit General
          <Divider />
        </>
      }
      okText="Save"
      onOk={form.submit}
    >
      <Form form={form} layout="vertical" initialValues={initialValues} size="small" onFinish={handleSubmit}>
        <Row gutter={[12, 0]}>
          <Col span={24}>
            <Form.Item label="Founder" name="founder">
              <Input placeholder="Enter company founder" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item label="Established date" name="dateOfEstablishment">
              <DatePicker
                placeholder="Select established date"
                className="tw-w-full"
                format={'YYYY/MM/DD'}
                suffixIcon={<LuCalendarDays size={18} color={COLOR.text.primary} />}
                disabledDate={current => current > dayjs().endOf('day')}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label="Email"
              name="companyEmail"
              rules={[{ type: 'email', message: 'Please enter a valid email' }]}
            >
              <Input placeholder="Enter company email" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item label="Phone number" name="companyPhoneNumber" rules={[]}>
              <Input placeholder="Company phone number" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item label="City" name="city">
              <Input placeholder="Enter city" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item label="Address" name="companyAddress">
              <Input placeholder="Enter company address" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label="Company website"
              name="companyWebsite"
              rules={[{ pattern: Pattern.url, message: 'Please enter a valid URL' }]}
            >
              <Input placeholder="Enter company website URL" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label="LinkedIn"
              name="linkedInLink"
              rules={[{ pattern: Pattern.url, message: 'Please enter a valid URL' }]}
            >
              <Input placeholder="Enter LinkedIn URL" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label="Instagram"
              name="instagramLink"
              rules={[{ pattern: Pattern.url, message: 'Please enter a valid URL' }]}
            >
              <Input placeholder="Enter Instagram URL" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default StartupGeneralEditModal;
