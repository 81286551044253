import { AvatarProps, Spin, Tooltip, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import 'antd/lib/modal/style';
import 'antd/lib/slider/style';
import { MdOutlinePhotoCamera } from 'react-icons/md';
import useUpload from '../hooks/useUpload';
import Avatar from './Avatar';
import { Role } from '../stores/role.store';

interface Props {
  onChange?: (url: string | null) => void;
  file?: string;
  maxSizeInMB?: number;
  role: Role;
}

function UploadAvatar({
  onChange,
  file,
  size = 100,
  maxSizeInMB = 2,
  role,
  ...rest
}: Props & AvatarProps) {
  const { fileData, loading, handleUpload, handleBeforeUpload } = useUpload({ file, maxSizeInMB, onChange });

  return (
    <Spin spinning={loading}>
      <ImgCrop showGrid cropShape="round" minZoom={1}>
        <Upload
          accept="image/*"
          customRequest={handleUpload}
          beforeUpload={handleBeforeUpload}
          showUploadList={false}
          maxCount={1}
          className="tw-cursor-pointer"
          progress={{
            strokeWidth: 4,
            showInfo: false,
          }}
        >
          <Tooltip title={fileData ? 'Edit profile photo' : 'Upload profile photo'}>
            <div
              className="tw-absolute tw-flex tw-items-center tw-justify-center tw-bottom-1 tw-right-1 tw-rounded-full tw-bg-background-white tw-z-10 tw-shadow-md"
              style={{ width: +size / 4, height: +size / 4 }}
            >
              <MdOutlinePhotoCamera className="tw-text-icon-body tw-shadow-xl" size={18} />
            </div>
          </Tooltip>
        </Upload>
      </ImgCrop>
      <Avatar role={role} src={fileData} size={size} alt="image" {...rest} />
    </Spin>
  );
}

export default UploadAvatar;
