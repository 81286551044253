import AuthLayout from '../../modules/auth/components/AuthLayout';
import ResetPasswordForm from '../../modules/auth/components/ResetPasswordForm';

const ResetPasswordPage = () => {
  return (
    <AuthLayout header="Reset Password" title="Reset Password" description="Enter your email to reset your password">
      <ResetPasswordForm />
    </AuthLayout>
  );
};

export default ResetPasswordPage;
