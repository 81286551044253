// @ts-ignore
import UserSettings from '../components/UserSettings';
import PageTitle from 'src/shared/components/PageTitle';
import PrivateLayout from '../shared/components/PrivateLayout';

const UserSettingsPage = () => {
  return (
    <PrivateLayout>
      <PageTitle title="User Settings" />
      <UserSettings />
    </PrivateLayout>
  );
};

export default UserSettingsPage;
