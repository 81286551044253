export const INDUSTRIES: string[] = [
  "Fintech",
  "Sports/Entertainment",
  "AI",
  "Agriculture",
  "Finance/Banking",
  "Sustainability/Clean Energy/GreenTech",
  "Diversity and Inclusion",
  "Blockchain",
  "Women in Tech",
  "Supply Chain/ Logistics",
  "EdTech",
  "Media",
  "Ecommerce",
  "Healthcare",
  "Cybersecurity",
  "Analytics",
  "PropTech/Real Estate",
  "BioTech",
];
