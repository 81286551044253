import PrivateLayout from '../shared/components/PrivateLayout';
// @ts-ignore
import FeedsContent from '../components/FeedsContent';

const FeedsPage = () => {
  return (
    <PrivateLayout>
      <FeedsContent />
    </PrivateLayout>
  );
};

export default FeedsPage;
