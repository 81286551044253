import { useParams } from 'react-router-dom';
import PostDetail from '../modules/post/components/PostDetail';
import PrivateLayout from '../shared/components/PrivateLayout';

const PostViewPage = () => {
  const { id } = useParams();

  return (
    <PrivateLayout>
      <PostDetail id={String(id)} />
    </PrivateLayout>
  );
};

export default PostViewPage;
