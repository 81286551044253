import { Divider, Form, Modal } from 'antd';
import { CreateInvestmentDto } from 'src/api/services/investment/investment.dto';
import { useCreateInvestment } from '../hooks/useCreateInvestment';
import InvestmentForm from './InvestmentForm';

interface Props {
  open: boolean;
  onCompleted: () => void;
  onClose: () => void;
}

const CreateInvestmentModal = ({ open, onCompleted, onClose }: Props) => {
  const [form] = Form.useForm<CreateInvestmentDto>();

  const { handleCreateInvestment, loading } = useCreateInvestment({
    onCompleted: () => {
      form.resetFields();
      onClose();
      onCompleted();
    },
  });

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      destroyOnClose
      width={732}
      cancelButtonProps={{ hidden: true }}
      okButtonProps={{ loading, size: 'small' }}
      okText="Add"
      onOk={form.submit}
      title={
        <>
          Add an investment
          <Divider />
        </>
      }
    >
      <Form form={form} layout="vertical" size="small" onFinish={handleCreateInvestment}>
        <InvestmentForm />
      </Form>
    </Modal>
  );
};

export default CreateInvestmentModal;
