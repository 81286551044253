import { Form, Button, Input } from 'antd';
import { SetPasswordDto } from 'src/api/services/auth/auth.dto';
import { useUpdatePasswordForm } from '../hooks/useUpdatePasswordForm';
import { Pattern } from 'src/shared/utils/validators';

/* UpdatePasswordForm is used with UpdatePasswordPage to set new password after reset password verification, different from user setting screen */
const UpdatePasswordForm = () => {
  const [form] = Form.useForm();
  const { handleSubmit, loading } = useUpdatePasswordForm();

  return (
    <Form form={form} size="large" layout="vertical" onFinish={handleSubmit}>
      <Form.Item<SetPasswordDto>
        label="Password"
        name="password"
        rules={[
          { required: true, message: 'Please enter your password' },
          {
            pattern: Pattern.password,
            message: 'Password: 1 lowercase, 1 uppercase, 1 number, 1 symbol, min. 8 characters.',
          },
        ]}
      >
        <Input.Password placeholder="Enter your password" />
      </Form.Item>

      <Form.Item<SetPasswordDto>
        label="Confirm Password"
        name="confirmPassword"
        rules={[
          { required: true, message: 'Please confirm your password' },
          {
            validator: (_, value) => {
              if (value && value !== form.getFieldValue('password')) {
                return Promise.reject('Passwords do not match');
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <Input.Password placeholder="Confirm your password" />
      </Form.Item>

      <Form.Item>
        <Button type="primary" loading={loading} className="tw-w-full tw-mt-6" htmlType="submit" size="large">
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default UpdatePasswordForm;
