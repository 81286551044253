/**
 * Converts a base64 encoded data URI to a blob.
 *
 * @param {string} dataURI The base64 encoded data URI.
 * @returns {Blob} The blob.
 */
export function b64toBlob(dataURI: string): Blob {

  var byteString = atob(dataURI.split(',')[1]);
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);

  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: 'image/jpeg' });
}

/**
 * Converts a blob to a base64 data URI.
 *
 * @param {Blob} blob The blob to be converted.
 * @returns {Promise<string>} A promise that resolves with the base64 data URI.
 */
export function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });
}
