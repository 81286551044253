import React, { useContext } from 'react';
import { RxCross2 } from 'react-icons/rx';
import { Col, Row } from 'react-bootstrap';
import { Helper } from '../utils/images';
import { useNavigate } from 'react-router-dom';
import { RoleContext } from '../context/RoleProvider';

/**
 * Banner to encourage users to follow more profiles
 * 
 * TODO Rename component to be more descriptive
 */
const HelperBanner = ({ onClose }) => {
  const navigate = useNavigate();
  const { setActiveMenuItem } = useContext(RoleContext);

  const handleExploreClick = (value) => {
    localStorage.setItem('activeMenuItem', value);

    setActiveMenuItem(value);
    navigate(`/${value}`);
  };
  return (
    <div className="helper-banner-bg">
      <div className="cross-helper d-flex justify-content-center align-items-center" onClick={onClose}>
        <RxCross2 className="icon-ex" size={15} />
      </div>
      <Row className="justify-content-center align-items-center">
        <Col lg={6} md={12} xs={12}>
          <div className="helper-content">
            <h1>Follow more profiles and stay updated on what's happening in the world.</h1>
            <p>Follow other profiles to stay updated with the latest news and highlights from our community.</p>
            <div className="d-flex align-items-center gap-2">
              <button className="view-button explore-btn mt-0" onClick={() => handleExploreClick('investors')}>
                Explore Investors
              </button>
              <button className="view-button explore-btn mt-0" onClick={() => handleExploreClick('startups')}>
                Explore Startups
              </button>
            </div>
          </div>
        </Col>
        <Col lg={6} md={12} xs={12}>
          <img src={Helper} alt="Banner" className="img-fluid d-block mx-auto" />
        </Col>
      </Row>
    </div>
  );
};

export default HelperBanner;
