export const AUTH_ROUTES = {
  LOGIN: '/auth/login',
  REGISTER: '/auth/register',
  SEND_VERIFICATION_EMAIL: '/auth/send-email',
  RESET_PASSWORD: '/auth/reset-password',
  // Old auth logic check if user has request to reset password
  CHECK_RESET_PASSWORD_STATUS: '/auth/check-reset-email',
  UPDATE_PASSWORD: '/auth/update-password',
  CHANGE_PASSWORD: '/auth/change-password',
  VERIFY_EMAIL: '/auth/verify-email',
  OTP_STATUS: '/auth/otp-status',
  SEND_OTP: '/auth/send-otp',
  VERIFY_OTP: '/auth/verify-otp',
};
