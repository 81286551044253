import React from 'react';
import { Error404Image } from '../utils/images';
import { useSearchParams } from 'react-router-dom';

interface ErrorPageProps {
  notFound?: boolean;
}

/**
 * Generic error page component
 *
 * This page was from the original MVP, feel free to improve/replace it. The 404 image was used everywhere originally.
 * 
 * TODO Missing proper css classes, e.g. <p> is white text on white background
 */
const ErrorPage: React.FC = ({ notFound }: ErrorPageProps) => {
  const [searchParams] = useSearchParams();
  const status = searchParams.get('status');
  const message = searchParams.get('message');
  return (
    <div className="main-error">
      <h1>An error occurred</h1>
      {(status === '404' || notFound) && (
        <div className="error-image">
          <img src={Error404Image} alt="404" />
        </div>
      )}
      <p>Please contact support</p>
      {message && <p>{message}</p>}
    </div>
  );
};

export default ErrorPage;
