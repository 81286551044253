import { useEffect } from 'react';
import useAuthStore from '../modules/auth/stores/auth.store';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AUTH_PATHS from './auth.paths';
import useRoleStore from '../shared/stores/role.store';
import PROFILE_PATHS from './profile.paths';

interface PrivateRouteProps {
  component: React.FC<any>;
  path: string;
}
// TODO Move Route component here, rather than in App.tsx
export default function PrivateRoute({ component: Component, path }: PrivateRouteProps) {
  const { loading, isAuthenticated } = useAuthStore();
  const { roles } = useRoleStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      // Wait for auth store to finish loading
      return;
    }
    if (!isAuthenticated) {
      if (path === '/') {
        navigate(`${AUTH_PATHS.LOGIN}`, { replace: true });
      }
      else {
        toast.info('Please log in to view this page.');
        // TODO path includes params, e.g. :id, need to handle this
        navigate(`${AUTH_PATHS.LOGIN}?redirect=${path}`, { replace: true });
      }
      return;
    }
    // API calls expect completed profile, redirect to setup if not completed
    // TODO Remove local storage check when new profile setup is complete
    if (!roles?.length && path !== PROFILE_PATHS.SETUP) {
      toast.info('Please complete account setup to proceed');
      navigate(PROFILE_PATHS.SETUP, { replace: true });
      return;
    }
  }, [loading, isAuthenticated, navigate, roles, path]);

  return !loading && isAuthenticated && (roles?.length || path === PROFILE_PATHS.SETUP) ? (
    <Component route={path} />
  ) : null;
}
