import { Button, Card, Divider, Flex, Space, Typography } from 'antd';
import InvestmentItem from './InvestmentItem';
import { FaChevronRight } from 'react-icons/fa6';
import { useState } from 'react';
import { useToggle } from '../../../shared/hooks/useToggle';
import CreateInvestmentModal from './CreateInvestmentModal';
import InvestmentService from 'src/api/services/investment/investment.service';
import useApiFetch from 'src/shared/hooks/useApiFetch';
import { GetInvestmentParams } from 'src/api/services/investment/investment.dto';
import UpdateInvestmentModal from './UpdateInvestmentModal';
import DeleteInvestmentModal from './DeleteInvestmentModal';
import Empty from 'src/shared/components/Empty';
import dayjs from 'dayjs';

const DEFAULT_NUMBER_OF_INVESTMENTS = 3;

interface Props {
  id: string;
  canUpdate: boolean;
}
const InvestmentList = ({ id, canUpdate }: Props) => {
  const [showAllInvestments, setShowAllInvestments] = useState(false);
  const { open: createModalOpen, onClose: onCreateModalClose, onOpen: onCreateModalOpen } = useToggle();
  const { open: updateModalOpen, onClose: onUpdateModalClose, onOpen: onUpdateModalOpen } = useToggle();
  const { open: deleteModalOpen, onClose: onDeleteModalClose, onOpen: onDeleteModalOpen } = useToggle();
  const [selectedInvestment, setSelectedInvestment] = useState<any>(null);

  const {
    data: investments,
    loading,
    refetch,
  } = useApiFetch<GetInvestmentParams, any>(InvestmentService.getInvestments, {
    id,
    page: 1,
    limit: 100,
  });

  const handleViewAllInvestments = () => {
    setShowAllInvestments(true);
  };

  const handleEdit = (id: string) => {
    const investment = investments?.find((investment: any) => investment.id === id);

    setSelectedInvestment({
      ...investment,
      dealDate: investment?.dealDate ? dayjs(investment?.dealDate) : null,
    });
    onUpdateModalOpen();
  };

  const handleDelete = (id: string) => {
    setSelectedInvestment({ id });
    onDeleteModalOpen();
  };

  const handleActionCompleted = () => {
    setSelectedInvestment(null);
    refetch();
  };

  if (!canUpdate && investments?.length === 0) {
    return null;
  }

  return (
    <>
      <CreateInvestmentModal open={createModalOpen} onClose={onCreateModalClose} onCompleted={handleActionCompleted} />

      <UpdateInvestmentModal
        open={updateModalOpen}
        onClose={onUpdateModalClose}
        onCompleted={handleActionCompleted}
        initialValues={selectedInvestment}
      />

      <DeleteInvestmentModal
        open={deleteModalOpen}
        onClose={onDeleteModalClose}
        onCompleted={handleActionCompleted}
        investmentId={selectedInvestment?.id}
      />

      <Card loading={loading}>
        <div className="tw-space-y-6">
          <Flex justify="space-between" align="center" wrap gap={8}>
            <div className="tw-flex-shrink-0 sm:tw-py-2">
              <Typography.Title level={4} className="tw-text-base tw-font-semibold tw-text-text-primary tw-mb-0">
                Investment portfolio
              </Typography.Title>
              {canUpdate && (
                <Typography.Text className="tw-text-sm tw-text-text-subTitle tw-mb-0">
                  Showcase your investments to date
                </Typography.Text>
              )}
            </div>
            {canUpdate && (
              <Button type="default" size="small" onClick={onCreateModalOpen}>
                Add an investment
              </Button>
            )}
          </Flex>

          <div>
            {investments?.length ? (
              <Space direction="vertical" size={0} className="tw-w-full">
                {investments
                  ?.slice(0, showAllInvestments ? investments?.length : DEFAULT_NUMBER_OF_INVESTMENTS)
                  ?.map((investment: any, index: number) => (
                    <div key={`investment-${investment?.id ?? index}`}>
                      <InvestmentItem
                        canUpdate={canUpdate}
                        onDelete={handleDelete}
                        onEdit={handleEdit}
                        {...investment}
                      />
                      {index !== investments?.length - 1 && <Divider />}
                    </div>
                  ))}

                {investments?.length > DEFAULT_NUMBER_OF_INVESTMENTS && !showAllInvestments && (
                  <Button
                    type="text"
                    className="tw-w-full tw-text-text-gray"
                    onClick={handleViewAllInvestments}
                    icon={<FaChevronRight />}
                    iconPosition="end"
                  >
                    Show More
                  </Button>
                )}
              </Space>
            ) : (
              <Flex justify="center" align="center" className="tw-w-full tw-py-8">
                <Empty
                  title="You haven't added any investments yet"
                  description="Start adding investments to showcase your achievements and enhance your profile"
                />
              </Flex>
            )}
          </div>
        </div>
      </Card>
    </>
  );
};

export default InvestmentList;
