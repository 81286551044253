import { Empty as AntdEmpty, Typography } from 'antd';
import { ReactComponent as PostIcon } from '../../assets/svgs/post.svg';

interface Props {
  icon?: React.ReactNode;
  title: string;
  description?: string;
}

/**
 * Empty component displays an empty state with a title and an optional description.
 *
 * @param {{ icon?: React.ReactNode, title: string, description?: string }} props
 * @returns {React.ReactElement}
 *
 * @example
 * <Empty title="No posts found" />
 * <Empty title="No posts found" description="You can create a post using the button below" />
 */
const Empty = ({ icon = <PostIcon />, title, description }: Props): React.ReactElement => {
  return (
    <AntdEmpty
      image={icon}
      description={
        <>
          <Typography.Paragraph className="tw-text-text-primary tw-font-medium tw-text-base tw-mb-1">{title}</Typography.Paragraph>
          {description && <Typography.Text className="tw-text-text-subTitle tw-text-sm">{description}</Typography.Text>}
        </>
      }
    />
  );
};

export default Empty;
