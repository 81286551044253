import { useNavigate } from 'react-router-dom';
import ProfileSetupLayout from '../../modules/profile-setup/components/ProfileSetupLayout';
import useSteps from '../../shared/hooks/useSteps';
import StartupProfileSetupForm from '../../modules/profile-setup/components/StartupProfileSetupForm';
import InvestorProfileSetupForm from '../../modules/profile-setup/components/InvestorProfileSetupForm';
import useRoleStore, { Role } from '../../shared/stores/role.store';
import { useState } from 'react';
import { toast } from 'react-toastify';
import useApiFetch from 'src/shared/hooks/useApiFetch';
import UserService from 'src/api/services/user/user.service';
import { Spin } from 'antd';
import useUserStore from 'src/shared/stores/user.store';
import { UserCurrentParams, UserCurrentResponse } from 'src/api/services/user/user.dto';
import useAuthStore from 'src/modules/auth/stores/auth.store';
import AUTH_PATHS from 'src/routes/auth.paths';
import { Button } from 'antd';
import { CiLogout } from 'react-icons/ci';
import SelectRoleForm from 'src/modules/profile-setup/components/SelectRoleForm';

export const PROFILE_LAYOUT_PROPS = {
  roleSetup: {
    header: 'Role Setup',
    title: 'Select Role',
    description:
      'To ensure you have the best experience on our platform, please select your role below, to set up your profile and start using features tailored to your needs.',
  },
  investor: {
    header: 'Investor Setup',
    title: 'Investor Setup',
    description: "The more information you provide, the more recommendations you'll receive for startups to follow.",
  },
  startup: {
    header: 'Startup Setup',
    title: 'Startup Setup',
    description: "The more information you provide, the more recommendations you'll receive for investors to follow.",
  },
};

const ProfileSetupPage = () => {
  const [roles, setRoles] = useState<Role[]>([]);
  const { logout } = useAuthStore();
  const { setUser, clearUser } = useUserStore();
  const { clearRoles } = useRoleStore();
  const userService = new UserService();
  const { loading, error, refetch } = useApiFetch<UserCurrentParams, UserCurrentResponse>(
    userService.getUserCurrent,
    {},
    {
      onError: error => {
        toast.error(error);
      },
      onSuccess: response => {
        const { userId, firstName, lastName, email, profilePhoto, companyName, companyProfilePhoto } = response.data;
        setUser({
          userId,
          firstName,
          lastName,
          email,
          profilePhoto,
          companyName,
          companyProfilePhoto,
        });
      },
    },
  );
  const navigate = useNavigate();
  const totalSteps = roles.length;
  const { step, nextStep, prevStep, isLastStep } = useSteps({ totalSteps });

  const showSelectRoleForm = !roles.length && step === 0;
  const showInvestorForm = roles.includes('investor') && step === 0;
  const showStartupForm = !showInvestorForm && roles.includes('startup');

  const handleNext = () => {
    if (isLastStep) {
      refetch();
      navigate('/');
    }
    else {
      nextStep();
    }
  };

  const handleLogout = () => {
    logout();
    clearUser();
    clearRoles();
    navigate(AUTH_PATHS.LOGIN);
  };

  const handleChangeSelectedRoles = ({ roles }: { roles: Role[] }) => {
    setRoles(roles);
  };

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <Spin spinning={loading}>
      <ProfileSetupLayout
        step={step}
        totalSteps={totalSteps}
        {...(showSelectRoleForm
          ? PROFILE_LAYOUT_PROPS.roleSetup
          : showInvestorForm
            ? PROFILE_LAYOUT_PROPS.investor
            : PROFILE_LAYOUT_PROPS.startup)}
      >
        {showSelectRoleForm && <SelectRoleForm onSubmit={handleChangeSelectedRoles} />}
        {showInvestorForm && <InvestorProfileSetupForm onNext={handleNext} />}
        {showStartupForm && (
          <StartupProfileSetupForm onNext={handleNext} onPrev={totalSteps > 1 ? prevStep : undefined} />
        )}
        <Button
          icon={<CiLogout size={20} />}
          type="text"
          size="small"
          className="tw-absolute tw-top-[-12px] tw-right-4 tw-text-text-subTitle"
          onClick={handleLogout}
        >
          Logout
        </Button>
      </ProfileSetupLayout>
    </Spin>
  );
};

export default ProfileSetupPage;
