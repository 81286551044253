import { COLOR } from '../constants';
import type { OverrideToken } from 'antd/es/theme/interface';

export const CHECKBOX: OverrideToken['Checkbox'] = {
  controlInteractiveSize: 20,
  colorPrimary: COLOR.background.primary,
  colorPrimaryHover: COLOR.background.primary,
  colorText: COLOR.text.primary,
  fontSizeLG: 16,
  fontSize: 16
};
