import { Form, Button, Input, Flex, Space, Typography } from 'antd';
import { Link } from 'react-router-dom';
import CaptchaCheckbox from './CaptchaCheckbox';
import { ResetPasswordDto } from 'src/api/services/auth/auth.dto';
import { useResetPasswordForm } from '../hooks/useResetPasswordForm';
import { useConfigStore } from 'src/shared/stores/config.store';

const ResetPasswordForm = () => {
  const [form] = Form.useForm();
  const { handleSubmit, loading, isEmailSent } = useResetPasswordForm();
  const { featureFlags } = useConfigStore();

  return (
    <Form form={form} size="large" layout="vertical" onFinish={handleSubmit}>
      <Space.Compact className="tw-w-full">
        <Form.Item<ResetPasswordDto>
          label="Email Address"
          className="tw-w-full"
          name="email"
          rules={[
            { required: true, message: 'Please enter your email address' },
            { type: 'email', message: 'Please enter a valid email address' },
          ]}
        >
          <Input placeholder="Enter your email address" />
        </Form.Item>
        <Form.Item label=" ">
          <Button type="primary" loading={loading} htmlType="submit" size="large">
            Send Link
          </Button>
        </Form.Item>
      </Space.Compact>

      {isEmailSent && !featureFlags.supabaseAuth && (
        <Typography.Paragraph className="tw-text-center tw-text-text-gray tw-text-base tw-mt-8">
          Please wait a few minutes and/or check if it may have gone to spam. Otherwise,
          <a
            href="/"
            className="tw-text-text-brand"
            onClick={e => {
              e.preventDefault();
              form.submit();
            }}
          >
            &nbsp;Resend Email
          </a>
        </Typography.Paragraph>
      )}

      <Flex className="tw-mt-6" vertical align="center" justify="center">
        <Form.Item<ResetPasswordDto>
          name="captcha"
          rules={[{ required: true, message: 'Please verify that you are not a robot' }]}
        >
          <CaptchaCheckbox />
        </Form.Item>
      </Flex>

      <Typography.Paragraph className="tw-text-center tw-text-text-gray tw-text-base tw-mt-8">
        Still having trouble accessing your Angelflow account? Send us an email at
        <Link
          to="/"
          className="tw-text-text-brand"
          onClick={() => (window.location.href = `mailto:${'support@angelflow.vc'}`)}
        >
          &nbsp;support@angelflow.vc
        </Link>{' '}
        and we'll get it sorted out for you.
      </Typography.Paragraph>
    </Form>
  );
};

export default ResetPasswordForm;
