import Description from '../../../shared/components/Description';
import { Button, Card, Col, Flex, Row, Tooltip, Typography } from 'antd';
import { CiGlobe } from 'react-icons/ci';
import { AiOutlineEdit } from 'react-icons/ai';
import dayjs from 'dayjs';
import { useToggle } from '../../../shared/hooks/useToggle';
import StartupGeneralEditModal from './StartupGeneralEditModal';
import { useRef } from 'react';
import { ReactComponent as LinkedInIcon } from '../../../assets/svgs/linkedin-logo.svg';
import { ReactComponent as InstagramIcon } from '../../../assets/svgs/instagram-logo.svg';

interface Props {
  data: any;
  canUpdate: boolean;
  refetch: () => void;
}

/**
 * StartupGeneralSection component renders the general information
 */
const StartupGeneralSection = ({ data, canUpdate, refetch }: Props) => {
  const { open, onClose, onOpen } = useToggle();
  const formInitialValuesRef = useRef({});

  const handleOpenEditModal = () => {
    onOpen();
    formInitialValuesRef.current = {
      founder: data?.founder,
      dateOfEstablishment: data?.dateOfEstablishment ? dayjs(data?.dateOfEstablishment) : undefined,
      companyEmail: data?.companyEmail,
      companyPhoneNumber: data?.companyPhoneNumber,
      companyAddress: data?.companyAddress,
      companyWebsite: data?.companyWebsite,
      city: data?.city,
      linkedInLink: data?.socialLinks?.linkedInLink,
      instagramLink: data?.socialLinks?.instagramLink,
    };
  };

  const handleCompleted = () => {
    refetch();
    onClose();
  };

  return (
    <>
      <StartupGeneralEditModal
        open={open}
        initialValues={formInitialValuesRef.current}
        onClose={onClose}
        onCompleted={handleCompleted}
      />
      <Card>
        <div className="tw-space-y-4">
          <Flex justify="space-between" align="center">
            <Typography.Title level={4} className="tw-text-base tw-font-semibold tw-text-text-primary tw-mb-0">
              Contact
            </Typography.Title>
            {canUpdate && (
              <Tooltip title="Edit profile">
                <Button type="default" size="small" onClick={handleOpenEditModal}>
                  <AiOutlineEdit size={20} />
                </Button>
              </Tooltip>
            )}
          </Flex>

          <Row gutter={[32, 8]}>
            <Col lg={8} md={24} xs={24} className="tw-space-y-2">
              <Description label="Founder">{data?.founder}</Description>
              <Description label="Email">{data?.companyEmail}</Description>
              <Description label="City">{data?.city}</Description>
            </Col>

            <Col lg={8} md={24} xs={24} className="tw-space-y-2">
              <Description label="Established">
                {data?.dateOfEstablishment ? dayjs(data?.dateOfEstablishment).format('MMM YYYY') : ''}
              </Description>
              <Description label="Phone number">{data?.companyPhoneNumber}</Description>
              <Description label="Address">{data?.companyAddress}</Description>
            </Col>

            <Col lg={8} md={24} xs={24} className="tw-space-y-2">
              <Flex vertical gap={24}>
                {data?.socialLinks?.linkedInLink && (
                  <a href={data?.socialLinks?.linkedInLink} target="_blank" rel="noreferrer">
                    <LinkedInIcon width={24} height={24} />{' '}
                    <Typography.Text className="tw-mb-0 tw-text-sm tw-text-text-primary">Linkedin</Typography.Text>
                  </a>
                )}

                {data?.socialLinks?.instagramLink && (
                  <a href={data?.socialLinks?.instagramLink} target="_blank" rel="noreferrer">
                    <InstagramIcon width={24} height={24} />{' '}
                    <Typography.Text className="tw-mb-0 tw-text-sm tw-text-text-primary">Instagram</Typography.Text>
                  </a>
                )}

                {data?.companyWebsite && (
                  <a href={data?.companyWebsite} target="_blank" rel="noreferrer">
                    <CiGlobe size={24} className="tw-text-text-primary" />{' '}
                    <Typography.Text className="tw-mb-0 tw-text-sm tw-text-text-primary">Website</Typography.Text>
                  </a>
                )}
              </Flex>
            </Col>
          </Row>
        </div>
      </Card>
    </>
  );
};

export default StartupGeneralSection;
