import AuthLayout from '../../modules/auth/components/AuthLayout';
import SignUpForm from '../../modules/auth/components/SignUpForm';

const SignUpPage = () => {
  return (
    <AuthLayout header="Sign Up" title="Create Your Account" description="Select sign up method">
      <SignUpForm />
    </AuthLayout>
  );
};

export default SignUpPage;
