import APIClient from '../../client';
import { BaseResponse } from '../../common';
import { FollowProfileDto, GetFollowersParams } from './profile.dto';

const ROUTES = {
  BASIC_PROFILE: '/api/basic-profile',
  UPDATE_STARTUP_PROFILE: '/api/startup-profile',
  UPDATE_INVESTOR_PROFILE: '/api/investor-profile',
  FOLLOW: '/api/follow',
  UNFOLLOW: '/api/un-follow',
  FOLLOWERS: '/api/followers',
};

const ProfileService = {
  basicProfile: (data: any): Promise<BaseResponse> => APIClient.post({ route: ROUTES.BASIC_PROFILE, data }),
  updateInvestorProfile: (data: any): Promise<BaseResponse> =>
    APIClient.put({ route: ROUTES.UPDATE_INVESTOR_PROFILE, data }),
  updateStartupProfile: (data: any): Promise<BaseResponse> =>
    APIClient.put({ route: ROUTES.UPDATE_STARTUP_PROFILE, data }),
  followProfile: (data: FollowProfileDto): Promise<BaseResponse> => APIClient.post({ route: ROUTES.FOLLOW, data }),
  unfollowProfile: (data: FollowProfileDto): Promise<BaseResponse> => APIClient.post({ route: ROUTES.UNFOLLOW, data }),
  getFollowers: ({ page, limit, role }: GetFollowersParams): Promise<any> =>
    APIClient.get({ route: `${ROUTES.FOLLOWERS}?role=${role}&page=${page ?? 1}&limit=${limit ?? 10}` }),
};

export default ProfileService;
