import type { OverrideToken } from 'antd/es/theme/interface';
import { COLOR } from '../constants';
import { convertHexToRgba } from '../utils';

export const SLIDER: OverrideToken['Slider'] = {
  handleColor: COLOR.border.brand,
  railSize: 8,
  handleSize: 24,
  handleSizeHover: 24,
  handleActiveColor: COLOR.border.brand,
  handleLineWidth: 2,
  handleActiveOutlineColor: convertHexToRgba(COLOR.border.brand, 0.2),
  handleLineWidthHover: 2,
  railBg: COLOR.background.gray,
  trackBg: COLOR.primary[300],
  trackHoverBg: COLOR.primary[300],
};
