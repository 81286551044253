import { Button, Card, Flex, Space, Typography } from 'antd';
import PostItem from '../../post/components/PostItem';
import { FaChevronRight } from 'react-icons/fa6';
import { useState } from 'react';
import { useToggle } from '../../../shared/hooks/useToggle';
import CreatePostModal from '../../../modules/post/components/CreatePostModal';
import Empty from 'src/shared/components/Empty';

const DEFAULT_NUMBER_OF_POSTS = 3;

interface Props {
  canUpdate: boolean;
  posts: any[];
  refetch: () => void;
}
const PostList = ({ canUpdate, posts, refetch }: Props) => {
  const [showAllPosts, setShowAllPosts] = useState(false);
  const { open, onClose, onOpen } = useToggle();

  const handleViewAllPosts = () => {
    setShowAllPosts(true);
  };

  return (
    <>
      <CreatePostModal open={open} onClose={onClose} onCompleted={refetch} />

      <Card>
        <div className="tw-space-y-4">
          <Flex justify="space-between" align="center">
            <Typography.Title level={4} className="tw-text-base tw-font-semibold tw-text-text-primary tw-mb-0">
              Activity
            </Typography.Title>
            {canUpdate && (
              <Button type="default" size="small" onClick={onOpen}>
                Create a post
              </Button>
            )}
          </Flex>

          <div>
            {posts?.length ? (
              <Space direction="vertical" size={14} className="tw-w-full">
                {posts
                  ?.slice(0, showAllPosts ? posts?.length : DEFAULT_NUMBER_OF_POSTS)
                  ?.map((post, index) => <PostItem key={`post-${index}`} {...post} />)}

                {posts?.length > DEFAULT_NUMBER_OF_POSTS && !showAllPosts && (
                  <Button
                    type="text"
                    className="tw-w-full tw-text-text-gray"
                    onClick={handleViewAllPosts}
                    icon={<FaChevronRight />}
                    iconPosition="end"
                  >
                    Show More
                  </Button>
                )}
              </Space>
            ) : (
              <Flex justify="center" align="center" className="tw-w-full tw-py-8">
                <Empty
                  title="You haven't posted yet"
                  description="Post your insights & updates to build your following"
                />
              </Flex>
            )}
          </div>
        </div>
      </Card>
    </>
  );
};

export default PostList;
