import { Flex, Image, Space, Typography } from 'antd';
import dayjs from 'dayjs';
import PostPlaceholderImage from '../../../assets/images/post-placeholder.png';
import { useNavigate } from 'react-router-dom';
interface Props {
  createdAt?: string;
  id: string;
  image?: string;
  title: string;
  description?: string;
}

const PostItem = ({ id, createdAt, image, title, description }: Props) => {
  const navigate = useNavigate();

  const handleRedirectToPostDetail = () => {
    navigate(`/post/${id}`);
  };

  return (
    <Space direction="vertical" size={8}>
      <Typography.Text className="tw-text-xs tw-text-text-placeholder">
        {dayjs(createdAt).format('DD MMM YYYY [at] HH:mm')}
      </Typography.Text>

      <Flex gap={12} align="start">
        <div className="tw-flex-shrink-0">
          <Image
            preview={false}
            width={80}
            height={80}
            src={image || PostPlaceholderImage}
            onError={e => (e.currentTarget.src = PostPlaceholderImage)}
            className="tw-bg-cover tw-bg-center tw-flex-shrink-0"
          />
        </div>

        <div className="tw-space-y-1">
          <Typography.Link
            href="#"
            onClick={handleRedirectToPostDetail}
            className="tw-mb-0 tw-text-base tw-font-semibold tw-text-text-primary hover:tw-underline"
          >
            {title}
          </Typography.Link>

          <Typography.Paragraph className="tw-text-base tw-text-text-placeholder" ellipsis={{ rows: 2 }}>
            {description}
          </Typography.Paragraph>
        </div>
      </Flex>
    </Space>
  );
};

export default PostItem;
