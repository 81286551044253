import dayjs from 'dayjs';
import { VscCalendar } from 'react-icons/vsc';
import { useNavigate } from 'react-router-dom';
import { Flex, Image, Typography } from 'antd';
import PostPlaceholderImage from 'src/assets/images/post-placeholder.png';
import { getTimeAgo } from '../utils/getTimeAgo';
import Avatar from 'src/shared/components/Avatar';

interface Props {
  newsfeed: any;
}

const FeedsCard = ({ newsfeed }: Props) => {
  const navigate = useNavigate();

  const handleReadMore = (id: string) => {
    navigate(`/post/${id}`);
    window.scroll(0, 0);
  };

  return (
    <Flex className="feeds-post" justify="start" gap={20}>
      <div>
        <Image
          preview={false}
          width={260}
          src={newsfeed?.image || PostPlaceholderImage}
          onError={e => (e.currentTarget.src = PostPlaceholderImage)}
          className="tw-bg-cover tw-bg-center tw-flex-shrink-0 tw-h-full tw-w-full tw-rounded-lg"
        />
      </div>
      <Flex vertical className="tw-w-full tw-min-h-full tw-mt-2">
        <Flex justify="space-between" align="center">
          <Typography.Title className="tw-font-semibold tw-text-lg tw-text-text-brand">
            {newsfeed?.title}
          </Typography.Title>
          <Typography.Text className="tw-text-xs tw-text-text-body tw-mb-0">
            {getTimeAgo(newsfeed?.createdAt)}
          </Typography.Text>
        </Flex>
        <span className="feed-time tw-mt-0 tw-mb-1">
          <VscCalendar />
          <Typography.Text className="tw-mb-0 tw-text-xs tw-text-text-gray tw-py-1">
            {dayjs(newsfeed?.createdAt).format('M/D/YYYY')}
          </Typography.Text>
        </span>
        <Typography.Paragraph
          className="tw-mt-2 tw-text-xs tw-text-text-subTitle tw-flex-grow-[1] tw-pb-5 tw-border-b-[1px] tw-border-solid tw-border-border-disabled"
          ellipsis={{ rows: 4 }}
        >
          {newsfeed?.description}
        </Typography.Paragraph>

        <Flex className="tw-flex-shrink-0" align="center" justify="space-between">
          <Flex align="center" gap={8}>
            <Avatar
              src={newsfeed?.userImage}
              role={newsfeed?.role}
              size={58}
              className="tw-border-border-brand tw-border-solid tw-border-[1px]"
            />
            <Typography.Paragraph
              className="mb-0 tw-text-sm tw-text-text-gray tw-font-semibold"
              ellipsis={{ rows: 1, tooltip: newsfeed?.name }}
            >
              {newsfeed?.name}
            </Typography.Paragraph>
          </Flex>
          <button className="view-button read-btn mt-0" onClick={() => handleReadMore(newsfeed.id)}>
            Read More
          </button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default FeedsCard;
