import ProfileService from '../../../api/services/profiles/profile.service';
import { useState } from 'react';
import { toast } from 'react-toastify';

interface Props {
  onCompleted: () => void;
}

export const useFollowProfile = ({ onCompleted }: Props) => {
  const [loading, setLoading] = useState(false);

  const handleFollowProfile = async (data: any) => {
    try {
      setLoading(true);

      const response = await ProfileService.followProfile({
        ...data,
      });
      
      if (response?.success) {
        onCompleted();
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error((error as Error)?.message);
    } finally {
      setLoading(false);
    }
  };

 
  return {
    handleFollowProfile,
    loading,
  };
};
