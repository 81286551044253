import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import EmailVerificationModal from '../../components/EmaiVerificationModal';
import OldApi from '../../api-old/api';
import { jwtDecode } from 'jwt-decode';
import { Check } from '../../utils/images';
import { Cross } from '../../utils/images';
import { isTokenExpired } from '../../utils';
import AUTH_PATHS from '../../routes/auth.paths';

// TODO This is from the MVP, it should be replaced
const EmailVerification = () => {
  const url = window.location.href;
  const parts = url.split('/');
  const token = parts[parts.length - 2];
  const navigate = useNavigate();
  const [verificationStatus, setVerificationStatus] = useState('pending');

  const handleVerifyEmail = useCallback(async () => {
    try {
      const response = await OldApi.emailVerification(token);
      if (response.success) {
        setVerificationStatus('success');
        // TODO Set session with Supabase cookie, login automatically
        setTimeout(() => navigate(AUTH_PATHS.LOGIN), 3000);
      }
      else toast.error(response?.message);
    }
    catch (error) {
      toast.error(error?.data?.message);
      navigate(AUTH_PATHS.LOGIN);
    }
  }, [navigate, token]);

  useEffect(() => {
    if (token) {
      const decodedAuthToken = jwtDecode(token);
      if (isTokenExpired(decodedAuthToken.exp)) {
        setVerificationStatus('error');
        const timeoutId = setTimeout(() => {
          navigate(AUTH_PATHS.LOGIN);
        }, 2000);
        return () => clearTimeout(timeoutId);
      }
      else {
        handleVerifyEmail();
      }
    }
    else {
      navigate(AUTH_PATHS.LOGIN);
    }
    // Token is grabbed from the URL by the old role provider, clear it to prevent misuse
    return () => localStorage.clear();
  }, [handleVerifyEmail, navigate, token]);

  return (
    <>
      {verificationStatus === 'success' && (
        <EmailVerificationModal
          show={true}
          onClose={() => setVerificationStatus('success')}
          title="Email Verified Successfully"
          description="Your email has been successfully verified. Redirecting to login..."
          icon={Check}
        />
      )}
      {verificationStatus === 'error' && (
        <EmailVerificationModal
          show={true}
          onClose={() => {
            setVerificationStatus('pending');
            navigate(AUTH_PATHS.LOGIN);
          }}
          title="Error"
          description="Link has expired. Please ask for another verification email."
          icon={Cross}
        />
      )}
    </>
  );
};

export default EmailVerification;
