import { create } from 'zustand';
import { setToken } from '../utils/token';

interface AuthState {
  loading: boolean;
  isAuthenticated: boolean;
  isRememberMe: boolean;
}

interface AuthActions {
  login: (token: string, isRememberMe?: boolean) => void;
  logout: () => void;
  setLoading: (loading: boolean) => void;
}

const useAuthStore = create<AuthState & AuthActions>(set => ({
  loading: true,
  isAuthenticated: false,
  isRememberMe: false,
  login: (token, isRememberMe) => {
    setToken(token, isRememberMe);
    set({ isAuthenticated: true, isRememberMe });
  },
  logout: () => {
    localStorage.clear();
    set({ isAuthenticated: false });
  },
  setLoading: (loading) => set({ loading }),
}));

export default useAuthStore;
