import { useCallback, useState } from 'react';

  /**
   * A hook that manages a boolean state and provides three functions to manipulate it.
   *
   * @param {boolean} initialState The initial state of the toggle. If not provided, the state is `false`.
   * @returns An object containing the state and three functions: `onOpen`, `onClose`, and `onToggle`.
   */
export const useToggle = (initialState?: boolean): {
  onClose: () => void;
  onOpen: () => void;
  onToggle: () => void;
  open: boolean;
} => {
  const [open, setOpen] = useState(initialState ?? false);

  const onOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onToggle = useCallback(() => {
    setOpen(!open);
  }, [open]);

  return {
    onClose,
    onOpen,
    onToggle,
    open,
  };
};
