import APIClient from '../../client';
import { BasePaginationParams, BaseResponse } from '../../common';
import { CreateInvestorDto, GetProfileParams, ProfileResponse } from './profile.dto';

const INVESTOR_ENDPOINTS = {
  GET_INVESTOR_PROFILE: '/api/investor',
  GET_ALL_INVESTORS: '/api/investors',
  CREATE_INVESTOR: '/api/investors',
  UPDATE_INVESTOR_PROFILE: '/api/investor-profile',
};

const InvestorService = {
  getInvestorProfile: ({ id, currentRole }: GetProfileParams = {}): Promise<ProfileResponse> => {
    const url = currentRole
      ? `${INVESTOR_ENDPOINTS.GET_INVESTOR_PROFILE}/${currentRole}/${id ?? ''}`
      : `${INVESTOR_ENDPOINTS.UPDATE_INVESTOR_PROFILE}/${id ?? ''}`;
    return APIClient.get({ route: url });
  },
  getAllInvestors: ({ page, limit }: BasePaginationParams): Promise<any> =>
    APIClient.get({ route: `${INVESTOR_ENDPOINTS.GET_ALL_INVESTORS}?page=${page ?? 1}&limit=${limit ?? 10}` }),
  createInvestor: (data: CreateInvestorDto | FormData): Promise<BaseResponse> =>
    APIClient.post({ route: INVESTOR_ENDPOINTS.CREATE_INVESTOR, data }),
  updateInvestorProfile: (data: any): Promise<BaseResponse> =>
    APIClient.put({ route: INVESTOR_ENDPOINTS.UPDATE_INVESTOR_PROFILE, data }),
};

export default InvestorService;
