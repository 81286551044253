import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import AUTH_PATHS from 'src/routes/auth.paths';
// @ts-ignore
import EmailVerificationModal from '../../components/EmaiVerificationModal';
import { Cross } from '../../utils/images';
import AuthService from 'src/api/services/auth/auth.service';

type ResultParams = { result: 'success' | 'error' };

/**
 * Shows result of password recovery verification
 *
 * User clicks a verify link in email which sends verify request to backend. Backend then redirects to here with either success or error result param
 */

const ResetPasswordResultPage = () => {
  const { result } = useParams<ResultParams>();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const authService = new AuthService();

  useEffect(() => {
    const accessToken = searchParams.get('access_token');
    const refreshToken = searchParams.get('refresh_token');

    if (accessToken && refreshToken) {
      authService.setSession(accessToken, refreshToken).then(() => {
        navigate(AUTH_PATHS.UPDATE_PASSWORD);
      });
    }
  }, [searchParams, navigate]);

  return (
    <>
      {result === 'error' && (
        <EmailVerificationModal
          show={true}
          onClose={() => {
            navigate(AUTH_PATHS.LOGIN);
          }}
          title="Verification Failed"
          description={searchParams.get('message') || 'Something went wrong. Please try again.'}
          icon={Cross}
        />
      )}
    </>
  );
};

export default ResetPasswordResultPage;
