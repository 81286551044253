import { Button, Grid, Image, ImageProps, Spin, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import 'antd/lib/modal/style';
import 'antd/lib/slider/style';
import { ReactNode } from 'react';
import { ReactComponent as Logo } from '../../assets/svgs/angel-logo-white.svg';
import { User } from '../../utils/images';
import useUpload from '../hooks/useUpload';

interface Props {
  onChange?: (url: string | null) => void;
  file?: string | null;
  maxSizeInMB?: number;
  defaultImage?: string | ReactNode;
}

function UploadCover({
  onChange,
  file,
  width = 100,
  height = 100,
  maxSizeInMB = 5,
  defaultImage = User,
  ...rest
}: Props & Omit<ImageProps, 'onChange'>) {
  const { sm, md } = Grid.useBreakpoint();
  const { fileData, loading, handleUpload, handleBeforeUpload } = useUpload({ file, maxSizeInMB, onChange });

  return (
    <Spin spinning={loading}>
      {fileData ? (
        <Image
          wrapperClassName="tw-w-full"
          preview={false}
          src={fileData}
          height={md ? height : undefined}
          alt="image"
          className="tw-object-cover tw-rounded-t-xl md:tw-min-h-[35vw]"
          {...rest}
        />
      ) : (
        <div className="tw-bg-background-black tw-text-center tw-rounded-t-xl">
          <Logo
            style={{
              height: '30vw',
              width: '35vw',
              maxHeight: height,
            }}
          />
        </div>
      )}
      <div className={`${sm ? 'tw-right-5 tw-top-5' : 'tw-right-3 tw-top-3'} tw-absolute tw-border-none`}>
        <ImgCrop showGrid aspect={+width / (+height || 1)} minZoom={1}>
          <Upload
            accept="image/*"
            customRequest={handleUpload}
            beforeUpload={handleBeforeUpload}
            showUploadList={false}
            maxCount={1}
            className="tw-cursor-pointer"
            progress={{
              strokeWidth: 4,
              showInfo: false,
            }}
          >
            <Button type="default" size="small">
              {fileData ? 'Edit cover photo' : 'Upload cover photo'}
            </Button>
          </Upload>
        </ImgCrop>
      </div>
    </Spin>
  );
}

export default UploadCover;
