import Description from '../../../shared/components/Description';
import { Button, Card, Col, Flex, Row, Tooltip, Typography } from 'antd';
import { CiGlobe } from 'react-icons/ci';
import { AiOutlineEdit } from 'react-icons/ai';
import { useToggle } from '../../../shared/hooks/useToggle';
import { useRef } from 'react';
import { ReactComponent as LinkedInIcon } from '../../../assets/svgs/linkedin-logo.svg';
import SyndicateGeneralEditModal from './SyndicateGeneralEditModal';

interface Props {
  data: any;
  canUpdate: boolean;
  refetch: () => void;
}

/**
 * SyndicateGeneralSection component renders the general information
 */
const SyndicateGeneralSection = ({ data, canUpdate, refetch }: Props) => {
  const { open, onClose, onOpen } = useToggle();
  const formInitialValuesRef = useRef({});

  const handleOpenEditModal = () => {
    onOpen();
    formInitialValuesRef.current = {
      leadName: data?.leadName,
      email: data?.email,
      phoneNumber: data?.phoneNumber,
      address: data?.address,
      linkedInLink: data?.socialLinks?.linkedInLink,
      websiteLink: data?.websiteLink,
    };
  };

  const handleCompleted = () => {
    refetch();
    onClose();
  };

  return (
    <>
      <SyndicateGeneralEditModal
        open={open}
        initialValues={formInitialValuesRef.current}
        onClose={onClose}
        onCompleted={handleCompleted}
      />
      <Card>
        <div className="tw-space-y-4">
          <Flex justify="space-between" align="center" wrap>
            <div className="tw-flex-shrink-0">
              <Typography.Title level={4} className="tw-text-base tw-font-semibold tw-text-text-primary tw-mb-0">
                General
              </Typography.Title>

              {canUpdate && (
                <Typography.Text className="tw-text-sm tw-text-text-subTitle tw-mb-0">
                  Contact information will not be shown publicly
                </Typography.Text>
              )}
            </div>
            {canUpdate && (
              <Tooltip title="Edit profile">
                <Button type="default" size="small" onClick={handleOpenEditModal}>
                  <AiOutlineEdit size={20} />
                </Button>
              </Tooltip>
            )}
          </Flex>

          <Row gutter={[32, 8]}>
            <Col lg={8} md={24} xs={24} className="tw-space-y-2">
              <Description label="Lead name">{data?.leadName}</Description>
              {canUpdate && <Description label="Email">{data?.email}</Description>}
            </Col>

            {canUpdate && (
              <Col lg={8} md={24} xs={24} className="tw-space-y-2">
                <Description label="Phone number">{data?.phoneNumber}</Description>
                <Description label="Address">{data?.address}</Description>
              </Col>
            )}

            <Col lg={8} md={24} xs={24} className="tw-space-y-2">
              <Flex vertical gap={24}>
                {data?.socialLinks?.linkedInLink && (
                  <a href={data?.socialLinks?.linkedInLink} target="_blank" rel="noreferrer">
                    <LinkedInIcon width={24} height={24} />{' '}
                    <Typography.Text className="tw-mb-0 tw-text-sm tw-text-text-primary">Linkedin</Typography.Text>
                  </a>
                )}

                {data?.websiteLink && (
                  <a href={data?.websiteLink} target="_blank" rel="noreferrer">
                    <CiGlobe size={24} className="tw-text-text-primary" />{' '}
                    <Typography.Text className="tw-mb-0 tw-text-sm tw-text-text-primary">Website</Typography.Text>
                  </a>
                )}
              </Flex>
            </Col>
          </Row>
        </div>
      </Card>
    </>
  );
};

export default SyndicateGeneralSection;
