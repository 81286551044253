import { Card, Layout, Typography } from 'antd';
import AngelLogo from '../../../shared/components/AngelLogo';
import Loader from '../../../shared/components/Loader';
import PageTitle from '../../../shared/components/PageTitle';
import StepDots from '../../../shared/components/StepDots';

interface Props {
  children: React.ReactNode;
  header: string;
  title: string;
  description?: string;
  loading?: boolean;
  step?: number;
  totalSteps?: number;
}

const ProfileSetupLayout = ({ children, header, title, description, loading, step, totalSteps }: Props) => {
  const hasMultipleSteps = !!totalSteps && Number(totalSteps) > 1;

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Layout className="tw-flex tw-min-h-screen tw-items-center tw-justify-center tw-bg-background-gray tw-bg-[url('../../assets/images/background-overlay.png')] tw-bg-cover tw-bg-no-repeat tw-p-12">
          <PageTitle title={header} />
          <Card className="tw-w-[606px] tw-max-w-[606px]" bordered={false}>
            {hasMultipleSteps && <StepDots step={Number(step)} totalSteps={totalSteps} />}
            <div className="tw-w-full tw-space-y-10">
              <div className="tw-space-y-8">
                <AngelLogo />
                <div>
                  <Typography.Title level={2} className="tw-mb-2 tw-text-[40px] tw-font-bold tw-text-text-primary">
                    {title}
                  </Typography.Title>

                  {description && (
                    <Typography.Paragraph className="tw-text-sm tw-text-text-subTitle">
                      {description}
                    </Typography.Paragraph>
                  )}
                </div>
              </div>

              {children}
            </div>
          </Card>
        </Layout>
      )}
    </>
  );
};

export default ProfileSetupLayout;
