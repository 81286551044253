import { useState } from 'react';
import { toast } from 'react-toastify';
import { b64toBlob, blobToBase64 } from '../../../shared/utils/files';
import { arrayToFormDataValue } from '../utils/arrayToFormDataValue';
import useUserStore from 'src/shared/stores/user.store';
import useRoleStore from 'src/shared/stores/role.store';
import InvestorService from 'src/api/services/profiles/investor.service';
import ProfileService from 'src/api/services/profiles/profile.service';

interface Props {
  onCompleted: () => void;
}

export const useInvestorSetupForm = ({ onCompleted }: Props) => {
  const { roles, addRole, setCurrentRole } = useRoleStore();
  const [loading, setLoading] = useState(false);
  const { setUser } = useUserStore();

  const hasCreatedInvestorProfile = roles.includes('investor');

  const handleUpdateInvestorProfile = async (data: any) => {
    try {
      setLoading(true);

      const formData = new FormData();

      Object.entries(data).forEach(([key, value]) => {
        if (['interestCategories', 'investmentStages'].includes(key)) {
          formData.append(key, arrayToFormDataValue(value));
        }
        else if (value) {
          const formValue = key === 'profilePhoto' ? b64toBlob(String(value)) : String(value);
          formData.append(key, formValue);
        }
      });

      const response = hasCreatedInvestorProfile
        ? await ProfileService.updateInvestorProfile(formData)
        : await InvestorService.createInvestor(formData);

      if (response?.success) {
        const profilePhotoBlob = formData.get('profilePhoto');
        const { firstName, lastName } = data;

        setUser({
          ...(firstName && { firstName }),
          ...(lastName && { lastName }),
        });

        if (profilePhotoBlob) {
          const profilePhoto = await blobToBase64(profilePhotoBlob as Blob);
          setUser({
            ...(profilePhoto && { profilePhoto }),
          });
        }

        onCompleted();
        toast.success('Investor profile updated successfully');
        addRole('investor');
        setCurrentRole('investor');
      }
      else {
        toast.error(response?.message);
      }
    }
    catch (error) {
      toast.error((error as Error)?.message);
    }
    finally {
      setLoading(false);
    }
  };

  return {
    handleUpdateInvestorProfile,
    loading,
  };
};
