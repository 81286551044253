import SyndicateService from '../../../api/services/profiles/syndicate.service';
import { useState } from 'react';
import { toast } from 'react-toastify';

interface Props {
  onCompleted: () => void;
}

export const useUpdateSyndicateProfilePrivacy = ({ onCompleted }: Props) => {
  const [loading, setLoading] = useState(false);

  const handleUpdateSyndicateProfilePrivacy = async ({ isPrivate }: { isPrivate: boolean }) => {
    try {
      setLoading(true);

      const response = await SyndicateService.updateSyndicateProfile({
        isPrivate,
      });

      if (response?.success) {
        onCompleted();
        toast.success(isPrivate ? 'Profile set to private' : 'Profile set to public');
      }
      else {
        toast.error(response?.message);
      }
    } 
    catch (error) {
      toast.error((error as Error)?.message);
    }
    finally {
      setLoading(false);
    }
  };

  return {
    handleUpdateSyndicateProfilePrivacy,
    loading,
  };
};
