import { useState, useEffect } from 'react';

const useInfiniteScroll = (loading: boolean, onScrollBottom: () => void) => {
  const [scrollToBottom, setScrollToBottom] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = document.documentElement?.scrollTop || document.body.scrollTop;
      const scrollHeight = document.documentElement?.scrollHeight || document.body.scrollHeight;
      const clientHeight = document.documentElement.clientHeight || window.innerHeight;
      const scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;

      if (scrolledToBottom && !loading) {
        setScrollToBottom(true);
      }
    };

    const checkImmediateScroll = () => {
      // Add a small offset to account for rounding errors
      const OFFSET = 100;
      const clientHeight = document.documentElement.clientHeight || window.innerHeight;
      const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;

      // If content height is less than or equal to viewport height, mark as "scrolled to bottom"
      if (scrollHeight <= clientHeight + OFFSET && !loading) {
        setScrollToBottom(true);
      }
    };

    checkImmediateScroll();
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loading]);

  useEffect(() => {
    if (scrollToBottom) {
      onScrollBottom();
      setScrollToBottom(false);
    }
  }, [scrollToBottom, onScrollBottom]);
};

export default useInfiniteScroll;
