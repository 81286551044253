import { Flex, Skeleton } from 'antd';
import useApiFetch from '../../../shared/hooks/useApiFetch';
import { GetProfileParams, ProfileResponse } from '../../../api/services/profiles/profile.dto';
import PageTitle from '../../../shared/components/PageTitle';
import HeadingSectionPrivate from './HeadingSectionPrivate';
import ErrorSection from '../../../shared/components/ErrorSection';
import InvestorService from '../../../api/services/profiles/investor.service';
import PostList from './PostList';
import InvestorHeadingSection from './InvestorHeadingSection';
import InvestorGeneralSection from './InvestorGeneralSection';
import InvestorIntroSection from './InvestorIntroSection';
import InvestmentList from 'src/modules/investment/components/InvestmentList';
import useRoleStore from 'src/shared/stores/role.store';

interface Props {
  handle?: string;
}

const InvestorProfile = ({ handle }: Props) => {
  const { currentRole } = useRoleStore();
  const {
    data: investor,
    loading,
    error,
    status,
    refetch,
  } = useApiFetch<GetProfileParams, ProfileResponse>(InvestorService.getInvestorProfile, {
    id: handle,
    // The legacy api route requires role, but need passing current role since it is a parameter in the database query, can remove once the api is refactored
    currentRole,
  });

  if (error) {
    // Users should create investor profile before see this page, provide special instructions
    if (status === 404) {
      return <ErrorSection error="Investor not found. Please create your investor profile to get started." />;
    }
    return <ErrorSection error={error} />;
  }

  const canUpdate = !handle;

  const isPrivateProfile = !canUpdate && investor?.isPrivateProfile;

  return (
    <div id="section" className="tw-w-full tw-max-w-[996px] tw-min-w-4/5">
      <Skeleton loading={loading}>
        <PageTitle title={investor?.firstName} />

        {isPrivateProfile ? (
          <HeadingSectionPrivate
            role="investor"
            profilePhoto={investor?.profilePhoto}
            coverPhoto={investor?.coverPhoto}
            name={`${investor?.firstName} ${investor?.lastName}`}
          />
        ) : (
          <Flex vertical gap={24} className="py-8 px-24">
            <InvestorHeadingSection
              canUpdate={canUpdate}
              profilePhoto={investor?.profilePhoto}
              coverPhoto={investor?.coverPhoto}
              firstName={investor?.firstName}
              lastName={investor?.lastName}
              handle={investor?.handle}
              country={investor?.country}
              numberOfFollowers={investor?.followersCount}
              isPrivateProfile={investor?.isPrivateProfile}
              isFollowing={investor?.isFollowing}
              investorStatus={investor?.investorStatus}
              mentor={investor?.mentor}
              isProfileOwner={investor?.isProfileOwner}
              id={investor?.id}
              refetch={refetch}
            />

            <InvestorGeneralSection data={investor} canUpdate={canUpdate} refetch={refetch} />

            <InvestorIntroSection
              about={investor?.about}
              interestCategories={investor?.interestCategories}
              investmentStages={investor?.investmentStages}
              canUpdate={canUpdate}
              refetch={refetch}
            />

            <InvestmentList id={investor?.handle} canUpdate={canUpdate} />

            <PostList posts={investor?.newsFeed} canUpdate={canUpdate} refetch={refetch} />
          </Flex>
        )}
      </Skeleton>
    </div>
  );
};

export default InvestorProfile;
