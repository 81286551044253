import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import FormLabel from "./FormLabel";
import OldApi from "../api-old/api";
import { toast } from "react-toastify";

const FeedbackModal = ({ show, setShowFeedbackModal, onClose, }) => {

    const token = localStorage.getItem("token");
    const [feedback, setFeedback] = useState("");
    const [loading, setLoading] = useState(false);
    const [showWarning, setShowWarning] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (feedback.trim() === "") {
            setShowWarning(true);
            return;
        }
        setShowWarning(false);
        setLoading(true);
        try {
            const response = await OldApi.sendFeedback(feedback, token);
            toast.success(response?.message);
            setShowFeedbackModal(true);
            setFeedback("");
            onClose();
        } catch (error) {
            console.error('Error sending feedback', error);
        } finally {
            setLoading(false);
        }
    };

    const handleFeedbackChange = (e) => {
        setFeedback(e.target.value);
        if (e.target.value.trim() !== "") {
            setShowWarning(false);
        }
    };

    return (
        <Modal show={show} onHide={onClose} className="modal-filter" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton >
                <Modal.Title>
                    <h1 className="feedback-heading">
                        Website Feedback & Recommendations
                    </h1>
                    <p className="feedback-desc">
                        This feedback will be sent to support@angelflow.vc
                    </p>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit}>
                    <div className="form-group d-flex flex-column gap-2 mt-2">
                        <FormLabel
                            className="form-label"
                            labelText="Feedback"
                        />
                        <textarea
                            className="form-input about-feed"
                            placeholder="Enter Feedback"
                            type="text"
                            name="feedback"
                            value={feedback}
                            onChange={handleFeedbackChange}
                        ></textarea>
                    </div>
                    {showWarning && <p className="error-message secondary-text mb-0 mt-2">Please enter feedback.</p>}
                    <div className="modal-footer-feedback d-flex justify-content-end gap-3 align-items-center">
                        <button
                            className="primary-btn modal-btn feedback-btn mt-1 d-flex gap-2 justify-content-center align-items-center "
                            type="submit"
                            disabled={loading}
                        >
                            Send
                            {loading ? (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            ) : (
                                <></>
                            )}
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default FeedbackModal;
