import React, { useContext, useEffect, useState } from 'react';
import { TbHome2, TbBadge } from 'react-icons/tb';
import { PiUsersThreeLight } from 'react-icons/pi';
import { TiWorldOutline } from 'react-icons/ti';
import { RiSettings4Line } from 'react-icons/ri';
import { HiOutlineChatBubbleLeft } from 'react-icons/hi2';
import { Link } from 'react-router-dom';
import Header from './Header';
import { RoleContext } from '../context/RoleProvider';
import { RxCross2 } from 'react-icons/rx';
import useRoleStore from '../shared/stores/role.store';

// TODO Implement followers for syndicates
const Sidebar = ({ children }) => {
  const { activeMenuItem, setActiveMenuItem } = useContext(RoleContext);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { currentRole } = useRoleStore();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    if (!isSidebarOpen) {
      document.body.classList.add('no-scroll');
    }
    else {
      document.body.classList.remove('no-scroll');
    }
  };

  const handleTabActive = tab => {
    localStorage.setItem('activeMenuItem', tab);
    setActiveMenuItem(tab);
  };

  useEffect(() => {
    const activeTab = localStorage.getItem('activeMenuItem');
    setActiveMenuItem(['/home', '/'].includes(window.location.pathname) || !activeTab ? 'home' : activeTab);
  }, [setActiveMenuItem]);

  return (
    <div className="dashboard">
      <span
        className={`overlay-styled ${isSidebarOpen ? 'is-active' : ''}`}
        onClick={() => setIsSidebarOpen(false)}
      ></span>
      <Header toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} />
      <div className="main-dashboard">
        <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
          <div className="cross-icon " onClick={toggleSidebar}>
            <RxCross2 className="opener" />
          </div>
          <Link to="/home">
            <div
              id="home"
              onClick={() => handleTabActive('home')}
              className={activeMenuItem === 'home' ? 'sidebar-item active' : 'sidebar-item'}
            >
              <TbHome2 className="sidebar-icon" />
              <span>Home</span>
            </div>
          </Link>
          <Link to="/investors">
            <div
              key="investors"
              onClick={() => {
                handleTabActive('investors');
              }}
              className={activeMenuItem === 'investors' ? 'sidebar-item active' : 'sidebar-item'}
            >
              <PiUsersThreeLight className="sidebar-icon" />
              <span>Investors</span>
            </div>
          </Link>
          <Link to="/startups">
            <div
              key="startups"
              onClick={() => {
                handleTabActive('startups');
              }}
              className={activeMenuItem === 'startups' ? 'sidebar-item active' : 'sidebar-item'}
            >
              <TbBadge className="sidebar-icon" />
              <span>Startups</span>
            </div>
          </Link>
          <Link to="/syndicates">
            <div
              key="syndicates"
              onClick={() => {
                handleTabActive('syndicates');
              }}
              className={activeMenuItem === 'syndicates' ? 'sidebar-item active' : 'sidebar-item'}
            >
              <PiUsersThreeLight className="sidebar-icon" />
              <span>Syndicates</span>
            </div>
          </Link>
          {currentRole !== 'syndicate' && (
            <Link to="/followers">
              <div
                key="followers"
                onClick={() => {
                  handleTabActive('followers');
                }}
                className={activeMenuItem === 'followers' ? 'sidebar-item active' : 'sidebar-item'}
              >
                <TiWorldOutline className="sidebar-icon" />
                <span>Following</span>
              </div>
            </Link>
          )}
          <Link to="/chat">
            <div
              key="chat"
              onClick={() => {
                handleTabActive('chat');
              }}
              className={activeMenuItem === 'chat' ? 'sidebar-item active' : 'sidebar-item'}
            >
              <HiOutlineChatBubbleLeft className="sidebar-icon" />
              <span>Inbox</span>
            </div>
          </Link>
          <Link to="/user-settings">
            <div
              key="user-settings"
              onClick={() => {
                handleTabActive('user-settings');
              }}
              className={activeMenuItem === 'user-settings' ? 'sidebar-item active' : 'sidebar-item'}
            >
              <RiSettings4Line className="sidebar-icon" />
              <span>User Settings</span>
            </div>
          </Link>
        </div>
        {children}
      </div>
    </div>
  );
};

export default Sidebar;
