import { AvatarProps, Avatar as AntdAvatar } from 'antd';
import { ReactComponent as CompanyLogoDefault } from '../../assets/svgs/company-logo-default.svg';
import { ReactComponent as InvestorLogoDefault } from '../../assets/svgs/investor-logo-default.svg';
import { Role } from '../stores/role.store';

interface Props extends AvatarProps {
  role: Role;
}

/**
 * Custom Ant Design Avatar component to display the default startup or investor logo if no src is provided.
 *
 * @param {Role} role The role of the user.
 * @param {string} [src] The source of the avatar image.
 * @param {AvatarProps} [props] Other props to be passed to the Ant Design Avatar component.
 * @returns {React.ReactElement | null} The rendered Avatar component.
 */
const Avatar = ({ role, src, ...props }: Props): React.ReactElement | null => {
  const defaultImage = role === 'investor' ? <InvestorLogoDefault /> : <CompanyLogoDefault />;

  return <AntdAvatar src={src || defaultImage} {...props} />;
};

export default Avatar;
