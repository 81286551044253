import React from "react";
import { Image, Typography, Flex } from 'antd';
import { default as AuthIntro } from '../../../assets/svgs/auth-intro.svg';

const DigitalBox: React.FC = () => {
  return (
    <Flex vertical className="digital-platform" justify="center" align="center">
      <Image src={AuthIntro} preview={false} width={328} height={320} />
      <Typography.Title level={3} className="tw-text-center tw-font-semibold tw-text-text-primary tw-mt-4">
        For Lead Investors
      </Typography.Title>
      <Typography.Paragraph className="tw-text-sm tw-text-text-primary tw-mb-0 tw-px-8">
        We’re changing the way you think about investing by accelerating your dealflow and investments.
      </Typography.Paragraph>
    </Flex>
  )
}

export default DigitalBox