import { Button, Card, Flex, Typography } from 'antd';
import { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from 'src/shared/components/Avatar';
import { Role } from 'src/shared/stores/role.store';

interface Props {
  role: Role;
  profilePhoto?: string;
  name: string;
  handle: string;
  country?: string;
}

const ProfileCard = ({ role, profilePhoto, name, handle, country }: PropsWithChildren<Props>) => {
  const navigate = useNavigate();

  const handleViewProfile = () => {
    navigate(`/${role}/${handle}`);
  };

  return (
    <Card size="small" className="tw-rounded-3xl tw-p-2">
      <Flex vertical justify="space-between" className="tw-w-full tw-min-h-[216px] tw-space-y-3 tw-text-center">
        <div className="tw-w-full tw-text-center tw-space-y-2">
          <Avatar src={profilePhoto} size={110} role={role} className="tw-border-4 tw-border-text-white" />
          <div className="tw-text-center">
            <Typography.Paragraph
              ellipsis={{ rows: 1, tooltip: name }}
              className="tw-mb-0 tw-text-base tw-font-semibold tw-text-text-primary tw-flex-shrink-0"
            >
              {name}
            </Typography.Paragraph>
            <Typography.Text className="tw-text-text-subTitle tw-text-xs">{country}</Typography.Text>
          </div>
        </div>

        <div>
          <Button
            type="default"
            className="tw-px-5 tw-border-none tw-bg-background-gray"
            size="small"
            onClick={handleViewProfile}
          >
            View profile
          </Button>
        </div>
      </Flex>
    </Card>
  );
};

export default ProfileCard;
