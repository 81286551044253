import { Select, SelectProps } from 'antd';
import { INVESTOR_STATUSES } from 'src/shared/constants/investorStatuses';

const investorStatusSelectOptions = INVESTOR_STATUSES.map(status => ({
  value: status,
  label: status,
}));

const InvestorStatusSelect = ({ ...rest }: SelectProps) => {
  return <Select options={investorStatusSelectOptions} {...rest} />;
};

export default InvestorStatusSelect;
