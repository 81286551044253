import { useState } from 'react';
import { toast } from 'react-toastify';
import { UpdateInvestmentDto } from 'src/api/services/investment/investment.dto';
import InvestmentService from 'src/api/services/investment/investment.service';

interface Props {
  investmentId: string;
  onCompleted: () => void;
}

export const useUpdateInvestment = ({ investmentId, onCompleted }: Props) => {
  const [loading, setLoading] = useState(false);

  const handleUpdateInvestment = async (data: UpdateInvestmentDto) => {
    try {
      setLoading(true);
      const response = await InvestmentService.updateInvestment(investmentId, data);

      if (response?.success) {
        onCompleted();
        toast.success('Investment updated successfully');
      }
      else {
        toast.error(response?.message);
      }
    }
    catch (error) {
      toast.error((error as Error)?.message);
    }
    finally {
      setLoading(false);
    }
  };

  return {
    handleUpdateInvestment,
    loading,
  };
};
