import { useParams } from 'react-router-dom';
import PrivateLayout from '../shared/components/PrivateLayout';
import SyndicateProfile from '../modules/profiles/components/SyndicateProfile';

const SyndicateViewPage = () => {
  const { handle } = useParams();

  return (
    <PrivateLayout>
      <SyndicateProfile handle={handle} />
    </PrivateLayout>
  );
};

export default SyndicateViewPage;
