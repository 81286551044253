import { Col, Divider, Form, Input, Modal, Row } from 'antd';
import { Pattern } from '../../../shared/utils/validators';
import { useUpdateSyndicateProfile } from '../hooks/useUpdateSyndicateProfile';
import { UpdateSyndicateDto } from 'src/api/services/profiles/syndicate.dto';

interface Props {
  open: boolean;
  initialValues?: any;
  onCompleted: () => void;
  onClose: () => void;
}

const SyndicateGeneralEditModal = ({ open, initialValues, onCompleted, onClose }: Props) => {
  const [form] = Form.useForm<UpdateSyndicateDto>();
  const { handleUpdateSyndicateProfile, loading } = useUpdateSyndicateProfile({ onCompleted });

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      destroyOnClose
      cancelButtonProps={{ hidden: true }}
      okButtonProps={{ loading, size: 'small' }}
      width={740}
      title={
        <>
          Edit General
          <Divider />
        </>
      }
      okText="Save"
      onOk={form.submit}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        size="small"
        onFinish={handleUpdateSyndicateProfile}
      >
        <Row gutter={[12, 0]}>
          <Col span={24}>
            <Form.Item label="Lead" name="leadName">
              <Input placeholder="Enter your syndicate lead's name" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item label="Email" name="email" rules={[{ type: 'email', message: 'Please enter a valid email' }]}>
              <Input placeholder="Enter syndicate's email" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item label="Phone number" name="phoneNumber">
              <Input placeholder="Enter syndicate's phone number" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item label="Address" name="address">
              <Input placeholder="Enter address" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label="Website"
              name="websiteLink"
              rules={[{ pattern: Pattern.url, message: 'Please enter a valid URL' }]}
            >
              <Input placeholder="Enter website URL" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label="LinkedIn"
              name="linkedInLink"
              rules={[{ pattern: Pattern.url, message: 'Please enter a valid URL' }]}
            >
              <Input placeholder="Enter LinkedIn URL" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default SyndicateGeneralEditModal;
