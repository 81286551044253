import SyndicateService from '../../../api/services/profiles/syndicate.service';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { UpdateSyndicateDto } from 'src/api/services/profiles/syndicate.dto';
import useUserStore from 'src/shared/stores/user.store';
import { blobToBase64 } from 'src/shared/utils/files';

interface Props {
  onCompleted: () => void;
}

export const useUpdateSyndicateProfile = ({ onCompleted }: Props) => {
  const { setUser } = useUserStore();
  const [loading, setLoading] = useState(false);

  const handleUpdateSyndicateProfile = async (data: UpdateSyndicateDto) => {
    try {
      setLoading(true);

      const response = await SyndicateService.updateSyndicateProfile(data);

      if (response?.success) {
        if (data instanceof FormData) {
          const profilePhotoBlob = data.get('profilePhoto');

          if (profilePhotoBlob) {
            const syndicateProfilePhoto = await blobToBase64(profilePhotoBlob as Blob);
            setUser({
              ...(syndicateProfilePhoto && { syndicateProfilePhoto }),
            });
          }
        }
        else {
          const { name: syndicateName } = data;
          setUser({
            ...(syndicateName && { syndicateName }),
          });
        }

        onCompleted();
        toast.success('Syndicate profile updated successfully');
      }
      else {
        toast.error(response?.message);
      }
    }
    catch (error) {
      toast.error((error as Error)?.message);
    }
    finally {
      setLoading(false);
    }
  };

  return {
    handleUpdateSyndicateProfile,
    loading,
  };
};
