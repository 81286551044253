import { Button, Card, Flex, Tag, Tooltip, Typography } from 'antd';
import { AiOutlineEdit } from 'react-icons/ai';
import Description from '../../../shared/components/Description';
import Paragraph from '../../../shared/components/Paragraph';
import { useToggle } from '../../../shared/hooks/useToggle';
import { useRef } from 'react';
import InvestorIntroEditModal from './InvestorIntroEditModal';

interface Props {
  about: string;
  interestCategories: string[];
  investmentStages: string[];
  canUpdate: boolean;
  refetch: () => void;
}

/**
 * InvestorIntroSection component renders the introduction section
 */
const InvestorIntroSection = ({ about, interestCategories, investmentStages, canUpdate, refetch }: Props) => {
  const { open, onClose, onOpen } = useToggle();
  const formInitialValuesRef = useRef({});

  const handleOpenEditModal = () => {
    onOpen();
    formInitialValuesRef.current = {
      about,
      interestCategories,
      investmentStages,
    };
  };

  const handleCompleted = () => {
    refetch();
    onClose();
  };

  return (
    <>
      <InvestorIntroEditModal
        open={open}
        initialValues={formInitialValuesRef.current}
        onClose={onClose}
        onCompleted={handleCompleted}
      />
      <Card>
        <div className="tw-space-y-4">
          <Flex justify="space-between" align="center" wrap>
            <Typography.Title level={4} className="tw-text-base tw-font-semibold tw-text-text-primary tw-mb-0">
              Introduction
            </Typography.Title>
            {canUpdate && (
              <Tooltip title="Edit profile">
                <Button type="default" size="small" onClick={handleOpenEditModal}>
                  <AiOutlineEdit size={20} />
                </Button>
              </Tooltip>
            )}
          </Flex>

          <div className="tw-space-y-4">
            <Description label="About">{about ? <Paragraph content={about} rows={3} /> : null}</Description>

            <Description label="Interested industries">
              {interestCategories?.length > 0 ? (
                <Flex gap={8} align="center" wrap>
                  {interestCategories?.map((item: string) => (
                    <Tag className="tw-m-0" key={item}>
                      {item}
                    </Tag>
                  ))}
                </Flex>
              ) : null}
            </Description>

            <Description label="Investment stages">
              {investmentStages?.length > 0 ? (
                <Flex gap={8} align="center" wrap>
                  {investmentStages?.map((item: string) => (
                    <Tag className="tw-m-0" key={item}>
                      {item}
                    </Tag>
                  ))}
                </Flex>
              ) : null}
            </Description>
          </div>
        </div>
      </Card>
    </>
  );
};

export default InvestorIntroSection;
