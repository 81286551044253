import { useState } from 'react';

interface Props {
  totalSteps: number;
  initialStep?: number;
}

const useSteps = ({ totalSteps, initialStep }: Props) => {
  const [step, setStep] = useState(initialStep ?? 0);

  const isLastStep = step === totalSteps - 1;

  const isFirstStep = step === 0;

  const nextStep = () => {
    if (isLastStep) return;
    setStep(step + 1);
  };

  const prevStep = () => {
    if (isFirstStep) return;
    setStep(step - 1);
  };

  const resetStep = () => {
    setStep(0);
  };

  return {
    nextStep,
    prevStep,
    resetStep,
    isFirstStep,
    isLastStep,
    step,
  };
};

export default useSteps;
