import StartupService from '../../../api/services/profiles/startup.service';
import { useState } from 'react';
import { toast } from 'react-toastify';
import useRoleStore from 'src/shared/stores/role.store';
import useUserStore from 'src/shared/stores/user.store';
import { blobToBase64 } from 'src/shared/utils/files';

interface Props {
  onCompleted: () => void;
}

export const useUpdateStartupProfile = ({ onCompleted }: Props) => {
  const { setUser } = useUserStore();
  const [loading, setLoading] = useState(false);
  const { roles, setRoles, setCurrentRole } = useRoleStore();

  const handleSubmit = async (data: any) => {
    try {
      setLoading(true);

      const response = await StartupService.updateStartupProfile(data);

      if (response?.success) {
        if (data instanceof FormData) {
          const profilePhotoBlob = data.get('profilePhoto');

          if (profilePhotoBlob) {
            const companyProfilePhoto = await blobToBase64(profilePhotoBlob as Blob);
            setUser({
              ...(companyProfilePhoto && { companyProfilePhoto }),
            });
          }
        }
        else {
          const { companyName } = data;
          setUser({
            ...(companyName && { companyName }),
          });
        }

        if (!roles.includes('startup')) {
          setRoles([...roles, 'startup']);
          setCurrentRole('startup');
        }

        onCompleted();
        toast.success('Startup profile updated successfully');
      }
      else {
        toast.error(response?.message);
      }
    }
    catch (error: any) {
      toast.error(error.message);
    }
    finally {
      setLoading(false);
    }
  };

  return {
    handleSubmit,
    loading,
  };
};
